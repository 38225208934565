import React, { useState, useMemo, useEffect } from 'react'
import Header from "../../components/header"
import Sidebar from "../../components/sidebar"
import { Form, Row, Col, Button, Toast, Spinner, Modal, InputGroup } from 'react-bootstrap'
import { FaWpforms, FaExclamationCircle, FaPlus } from 'react-icons/fa'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import { riskActions } from '../../actions/riskinput'
import { connect } from 'react-redux'
import SelectSearch from 'react-select-search';
import { usersActions } from '../../actions/users'
import CreatableSelect from 'react-select/creatable'
import { css } from "@emotion/core";
import MoonLoader from "react-spinners/MoonLoader";
import { useHistory } from "react-router-dom"
import Select from 'react-select';
import axios  from 'axios';

import LogoutModal from '../../components/Cancelsubscription'

// const baseurl = process.env.REACT_APP_BASE_URL;
const baseUrl = 'https://foresightapi.infrahub.co/'
const baseUrl1 = "http://localhost:8081/"


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
var zohoConn = localStorage.getItem('zoho_conn');

const probabilityOptions = [
    { value: '0.05', label: '10%' },
    { value: '0.1', label: '20%' },
    { value: '0.25', label: '30%' },
    { value: '0.4', label: '40%' },
    { value: '0.5', label: '50%' },
    { value: '0.6', label: '60%' },
    { value: '0.75', label: '70%' },
    { value: '0.9', label: '80%' },
    { value: '0.95', label: '90%' },
    { value: '1', label: '100%' },
];

const Risks = props => {
    const history = useHistory()
    const [projtype, setProjType] = useState('Direct Project')
    const [projname, setProjName] = useState('')
    const [projnum, setProjNum] = useState('')
    const [riskhaz, setRiskHaz] = useState('')
    const [cause, setCause] = useState('')
    const [cntrlone, setCntrlOne] = useState('')
    const [cntrltwo, setCntrlTwo] = useState('')
    const [cntrlthree, setCntrlThree] = useState('')
    const [bsaofest, setBsaofEst] = useState('')
    const [lowcost, setLowCost] = useState('')
    const [likelycost, setLikelyCost] = useState('')
    const [highcost, setHighCost] = useState('')
    const [deterisk, setDeteRisk] = useState(0)
    const [stausvalue, setStausValue] = useState()
    const [ctyvalue, setCtyValue] = useState()
    const [riskrepovalue, setRiskRepovalue] = useState()
    const [riskownvalue, setRiskOwnvalue] = useState('')
    const [probabilityvalue, setProbabilityvalue] = useState()
    const [iterationvalue, setIterationvalue] = useState()
    const [datevalue, onChange] = useState(new Date())
    const [realisationdate, onSelect] = useState(new Date())
    const [showtoast, setShowToast] = useState(false);
    const [toastmsg, setToastMsg] = useState('');
    const [msgclass, setMsgClass] = useState('');
    const [hidebtnload, setHideBtnLoad] = useState(true);
    const [isvalidate, setIsvalidate] = useState(false);
    const [userarray, setArray] = useState([{ value: '', name: '' }]);
    const [edituid, setEditUID] = useState();
    const [trianinv, setTrianInv] = useState('');
    const [montechecked, setMonteChecked] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [pageloader, setPageLoader] = useState(false)
    const [value, setValue] = useState(undefined)
    const [options, setOptions] = useState()
    const [riskid, setRiskId] = useState('')
    const [newproject, setNewproject] = useState(true)
    const [show, setShow] = useState(false)
    const [iterationChange, setIterationChange] = useState(false)
    const [existingIteration, setExistingIteration] = useState(true)
    const [probabilitySelectValue, setProbabilitySelectValue] = useState({ value: '0.05', label: '10%' })
    const [checkprbval, setCheckPrbVal] = useState(false)
    const [projectClassname, setProjectClassname] = useState('projectNameField')
    const [zohotaskid, setZohoTaskID] = useState('')
    const [zohoissueid, setZohoIssueId] = useState('')
    //new updates
    const [risksources, setrisksources] = useState()
    const [riskcategories, setriskcategories] = useState()
    const [mitigationstatus, setmitigationstatus] = useState('')
    const [mitigationPlannedClosureDate, setmitigationPlannedClosureDate] = useState(new Date())
    const [mitigationActualClosureDate, setmitigationActualClosureDate] = useState(new Date())
    const [contingencystatus, setcontingencystatus] = useState('')
    const [contingencyPlannedClosureDate, setcontingencyPlannedClosureDate] = useState(new Date())
    const [contingencyActualClosureDate, setcontingencyActualClosureDate] = useState(new Date())
    const [actionImplemented, setactionImplemented] = useState('')
    const [closureDate, setclosureDate] = useState(new Date())
    const [remarks, setremarks] = useState('')
    const [impact, setimpact] = useState(1)
    const [riskExposure, setriskExposure] = useState(0.05)
    const [risklevel, setrisklevel] = useState("Insignificant")
    const [fields, setFields] = useState([{ value: '' }]);
    const [userDefinedvalue, setuserDefinedvalue] = useState([{ value: '' }]);
    const [logouloder,setlogouloder] = useState(false)

    const getApiTest = async () => {
        try {
            let valueTest = await  axios.get(baseUrl+'risks/projects/'+ localStorage.getItem('companyid'),{headers:{"x-access-token":localStorage.getItem('token')}})
             
            if(valueTest.data.status == 5) {
                setlogouloder(true) 
            }
             
        } catch (error) {
          console.log({error:error.message})
        }
      }

    useEffect(() => {
        getApiTest()
        if (localStorage.getItem('uid') === null) {
            return window.location = '/';
        } else {
            // one month subscription
            // var subEndDate = new Date(localStorage.getItem('subscriptionEndDate'));
            // if (subEndDate >= new Date()) {

            props.getusers()
            props.getProjects()
            setPageLoader(true)
            var getToken = props.location.search
            var trimToken = getToken.replace("?token=", "")
            if (trimToken.length === 24) {
                setEditUID(trimToken)
                props.getExactrisk(trimToken)
            }
            else {
                setIterationvalue('5000')
                setProbabilityvalue('0.01')
                setCtyValue('General')
                setStausValue('Open')
            }
            setPageLoader(false)
            // }
            //  else {
            //     history.push('/subscriptionhasended')
            // }
        }
    }, [])

    const createOption = (label) => ({
        label,
        value: label,
    });

    const handleChange = (newValue, actionMeta) => {
        setValue(newValue);
        if (newValue !== null) {
            if (newValue.pnumber) {
                setNewproject(false)
                setProjName(newValue.label)
                setProjNum(newValue.pnumber)
                setIterationvalue('5000')
            } else {

                setProjName(newValue.label)
                let existProject = props.getall_risk_suc.data.filter((values) => newValue.value === values.project_name)
                if (existProject.length === 1) {
                    setNewproject(false)
                    setProjNum(existProject[0].project_number)
                    setIterationvalue(existProject[0].iterations)
                    setExistingIteration(existProject[0].iterations)
                } else {
                    setNewproject(true)
                    setProjNum('')
                    setIterationvalue('5000')
                }

            }
        } else {
            setProjNum('')
            setIterationvalue('5000')
        }
    };

    const state = {
        isLoading: false,
        options: options,
        value: undefined,
    };

    const probChange = (newValue, actionMeta) => {
        console.groupEnd();
        if (actionMeta.action === 'select-option') {
            setProbabilitySelectValue({ value: newValue.value, label: newValue.label })
            setDeteRisk(newValue.value * likelycost)
            let sum = newValue.value * impact;
            setriskExposure(sum.toFixed(2))
            if (sum > 8.55) {
                setrisklevel("Occurred")
            } else if (sum > 7.2) {
                setrisklevel("Dangerous")
            } else if (sum > 5.25) {
                setrisklevel("Extremely High")
            } else if (sum > 3.6) {
                setrisklevel("Very High")
            } else if (sum > 2.5) {
                setrisklevel("High")
            } else if (sum > 1.6) {
                setrisklevel("Moderate")
            } else if (sum > 0.75) {
                setrisklevel("Low")
            } else if (sum > 0.2) {
                setrisklevel("Very Low")
            } else if (sum > 0.05) {
                setrisklevel("Extremely Low")
            } else if (sum > 0.01) {
                setrisklevel("Insignificant")
            }

        } else if (actionMeta.action === 'create-option' && !checkprbval) {
            let perValue = newValue.label / 100
            setProbabilitySelectValue({ value: perValue, label: newValue.label + '%' })
            setDeteRisk(perValue * likelycost)
        }
    };

    const handleProbChange = (inputValue, actionMeta) => {

        if (actionMeta.action === 'input-change') {
            if (inputValue.length === 0) {
                setCheckPrbVal(false)
            } else if ((/[a-zA-Z]/).test(inputValue)) {
                setCheckPrbVal(true)
            } else if (inputValue.length > 2) {
                if (parseInt(inputValue) > 100) {
                    setCheckPrbVal(true)
                }
            } else if (!inputValue.match(/^[0-9a-z]+$/) && inputValue.match(/^[0-9a-z]+$/) !== null) {
                setCheckPrbVal(true)
            } else {
                setCheckPrbVal(false)
            }
        }
    };

    const handleCreate = (inputValue) => {
        setProjNum('')
        setIsLoading(true);
        setProjName(inputValue);
        setTimeout(() => {
            const { options } = state;
            const newOption = createOption(inputValue);
            setNewproject(true)
            console.groupEnd();
            setIsLoading(false)

            setOptions([...options, newOption])
            setValue(newOption)
        }, 500);
    };

    const handleClose = () => {
        setShow(false)
        if (iterationvalue === '100000') {
            setIterationvalue('5000')
        } else {
            setIterationvalue('100000')
        }
    }

    const onSelectIteration = () => {
        setShow(false)
    }

    const handleFieldValue = (e) => {
        switch (e.target.name) {
            case 'projname':
                return setProjName(e.target.value)
            case 'projnum':
                return setProjNum(e.target.value)
            case 'riskid':
                return setRiskId(e.target.value)
            case 'riskhaz':
                return setRiskHaz(e.target.value)
            case 'cause':
                return setCause(e.target.value)
            case 'cntrlone':
                return setCntrlOne(e.target.value)
            case 'cntrltwo':
                return setCntrlTwo(e.target.value)
            case 'cntrlthree':
                return setCntrlThree(e.target.value)
            case 'bsaofest':
                return setBsaofEst(e.target.value)
            case 'lowcost':
                return setLowCost(e.target.value)
            case 'likelycost':
                setLikelyCost(e.target.value)
                setDeteRisk(e.target.value * probabilitySelectValue.value)
                break;
            case 'highcost':
                return setHighCost(e.target.value)
            //New update
            case 'mitigationstatus':
                return setmitigationstatus(e.target.value)
            case 'contingencystatus':
                return setcontingencystatus(e.target.value)
            case 'actionimplemented':
                return setactionImplemented(e.target.value)
            case 'remarks':
                return setremarks(e.target.value)
            default:
        }
    }

    const onChangeStatus = (e) => {
        console.log(e.target.value,"binnnnnnn");
        setStausValue(e.target.value)
    }

    //New updates
    const onChangeRiskSources = (e) => {
        setrisksources(e.target.value)
    }

    const onChangeRiskCategories = (e) => {
        setriskcategories(e.target.value)
    }

    const onChangeImpact = (e) => {
        setimpact(e.target.value)
        let sum = probabilitySelectValue.value * e.target.value;
        setriskExposure(sum.toFixed(2))
        if (sum > 8.55) {
            setrisklevel("Occurred")
        } else if (sum > 7.2) {
            setrisklevel("Dangerous")
        } else if (sum > 5.25) {
            setrisklevel("Extremely High")
        } else if (sum > 3.6) {
            setrisklevel("Very High")
        } else if (sum > 2.5) {
            setrisklevel("High")
        } else if (sum > 1.6) {
            setrisklevel("Moderate")
        } else if (sum > 0.75) {
            setrisklevel("Low")
        } else if (sum > 0.2) {
            setrisklevel("Very Low")
        } else if (sum > 0.05) {
            setrisklevel("Extremely Low")
        } else if (sum > 0.01) {
            setrisklevel("Insignificant")
        }

    }



    function handleAddfields(i, event) {
        const values = [...fields];
        values[i].value = event.target.value;
        setFields(values);
    }

    function handleAddvalues(i, event) {

        const values1 = [...userDefinedvalue];
        values1[i].value = event.target.value;
        setuserDefinedvalue(values1);
    }

    function handleAdd() {
        const values = [...fields];
        const values1 = [...userDefinedvalue];
        values1.push({ value: '' });
        values.push({ value: '' });
        setFields(values);
        setuserDefinedvalue(values1);
    }

    function handleRemove(i) {
        const values = [...fields];
        const values1 = [...userDefinedvalue];
        values.splice(i, 1);
        values1.splice(i, 1);
        setFields(values);
        setuserDefinedvalue(values1);
    }

    //

    const onChangeCategory = (e) => {
        setCtyValue(e.target.value)
    }

    const onChangeProbability = (e) => {
        setProbabilityvalue(e.target.value)
        setDeteRisk(e.target.value * likelycost)
    }

    const onChangeIteration = (e) => {
        if (existingIteration !== e.target.value && !newproject) {
            newproject ? setShow(false) : setShow(true)
            newproject ? setIterationChange(false) : setIterationChange(true)
        }
        setIterationvalue(e.target.value)
    }

    const handleOnChange = (e) => {

        setProjType(e)
        if (e == "Zoho Project") {

            if (localStorage.getItem('role') === 'admin') {
                setIsLoading(true);
                setProjectClassname('projectNameField84')
                var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN')
                axios.get(baseUrl + 'index/zohoprojects/' + localStorage.getItem('uid'), { headers: { authorization: AuthStr } })
                    .then(response => {
                        setOptions(response.data.data.projects.map(items => {
                            return {
                                value: items.name,
                                label: items.name,
                                pnumber: items.id_string
                            }
                        }))

                        setIsLoading(false);
                        setProjectClassname('')
                    }).catch(function (error) {
                        console.log(error);
                    })
            }
            // else {
            //     axios.get(`http://localhost:8081/user/testapi?role=${localStorage.getItem('role')}&companyId=${localStorage.getItem('companyid')}&type=zohoProject`).then(function (response) {
            //         setOptions(response.data.list.map((items, index) => {
            //             return {
            //                 value: items.label,
            //                 label: items.label,
            //                 pnumber: items.pnumber
            //             }
            //         }))
            //     })


            // }
        }
        else {
            if (localStorage.getItem('role') === 'admin') {
                console.log(props.getall_risk_suc.data,"ppp");
                setOptions(props.getall_risk_suc.data.map(items => {
                    return {
                        value: items.project_name,
                        label: items.project_name
                    }
                }))
            }
            //  else {
            //     axios.get(`http://localhost:8081/user/testapi?role=${localStorage.getItem('role')}&companyId=${localStorage.getItem('companyid')}&type=directProject`).then(function (response) {

            //         setOptions(response.data.list.map((items, index) => {

            //             return {
            //                 value: items.label,
            //                 label: items.label
            //             }
            //         }))





            //     })
            // }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsvalidate(true)
        if (montechecked ? riskhaz &&cntrltwo && cntrlone && likelycost && lowcost && highcost : riskhaz && cntrlone && likelycost && riskid && value  !== undefined) {
            let data = {
                "project_type": projtype,
                "project_name": projname,
                "project_number": projnum,
                "risk": riskhaz,
                "riskid": riskid,
                "cause": cause,
                "control1": cntrlone,
                "control2": cntrltwo,
                "control3": cntrlthree,
                "estimate": bsaofest,
                "status": stausvalue,
                "category": ctyvalue,
                "reporter": riskrepovalue,
                "owner": riskownvalue,
                "risk_date": moment(datevalue).format("YYYY-MM-DD"),
                "realisation_date": moment(realisationdate).format("YYYY-MM-DD"),
                "probability": probabilitySelectValue.value,
                "lowcost": lowcost,
                "likelycost": likelycost,
                "highcost": highcost,
                "deterministicrisk": deterisk,
                "iterations": iterationvalue,
                "trianinv_mcs_js": "",
                "createdby": localStorage.getItem('uid'),
                "companyid": localStorage.getItem('companyid'),
                "newproject": newproject,
                "iterationChange": iterationChange,
                'zohotaskid': zohotaskid,
                "zohoissueid": zohoissueid,
                "risksource": risksources,
                'riskcatagory': riskcategories,
                'impact': impact,
                'risklevel': risklevel,
                'riskexposure': riskExposure,
                'mitigationstatus': mitigationstatus,
                'contingencystatus': contingencystatus,
                'actionimplemented': actionImplemented,
                'remarks': remarks,
                'mitigationplannedclosure': moment(mitigationPlannedClosureDate).format("YYYY-MM-DD"),
                'mitigationactualclosure': moment(mitigationActualClosureDate).format("YYYY-MM-DD"),
                'contingencyactualclosuredate': moment(contingencyActualClosureDate).format("YYYY-MM-DD"),
                'contingencyplannedclosuredate': moment(contingencyPlannedClosureDate).format("YYYY-MM-DD"),
                'closuredate': moment(closureDate).format("YYYY-MM-DD"),
                'customfield': fields,
                'customfieldvalue': userDefinedvalue,
                // "project_types": projtype,

            }

            setHideBtnLoad(false)
            edituid ? props.updaterisk(data, edituid) : props.addrisk(data)
            // edituid ? console.log(data, edituid,"1111111111111") :console.log(data,"22222222222222222")
        }
    }

    const removeToast = () => {
        setTimeout(() => {
            setShowToast(false)
            setToastMsg('')
        }, 5000)
    }

    useMemo(() => {
        if (props.add_risk_suc) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            setShowToast(true)
            setToastMsg(props.add_risk_suc.message)
            setMsgClass('success')
            setHideBtnLoad(true)
            setProjName('')
            setProjNum('')
            setRiskId('')
            setRiskHaz('')
            setCause('')
            setCntrlOne('')
            setCntrlTwo('')
            setCntrlThree('')
            setBsaofEst('')
            setLowCost('')
            setIterationvalue('5000')
            setProbabilityvalue('0.01')
            setCtyValue('General')
            setStausValue('Open')
            setLikelyCost('')
            setHighCost('')
            setDeteRisk('')
            setRiskRepovalue()
            setRiskOwnvalue()
            onChange(new Date())
            onSelect(new Date())
            setIsvalidate(false)
            removeToast()
            setPageLoader(false)
            //new update
            setmitigationPlannedClosureDate(new Date())
            setmitigationActualClosureDate(new Date())
            setcontingencyPlannedClosureDate(new Date())
            setcontingencyActualClosureDate(new Date())
            setclosureDate(new Date())
        }
    }, [props.add_risk_suc])

    useMemo(() => {
        if (props.add_risk_fail) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            setShowToast(true)
            setToastMsg(props.add_risk_fail)
            setMsgClass('error')
            setHideBtnLoad(true)
            removeToast()
            setPageLoader(false)
        }
    }, [props.add_risk_fail])

    useMemo(() => {
        if (props.udt_risk_suc) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            setShowToast(true)
            setToastMsg(props.udt_risk_suc.message)
            setMsgClass('success')
            setHideBtnLoad(true)
            removeToast()
            setPageLoader(false)
        }
    }, [props.udt_risk_suc])

    useMemo(() => {
        if (props.udt_risk_fail) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            setShowToast(true)
            setToastMsg(props.udt_risk_fail.message)
            setMsgClass('error')
            setHideBtnLoad(true)
            removeToast()
            setPageLoader(false)
        }
    }, [props.udt_risk_fail])

    useMemo(() => {
        
        if (props.getallusers_suc) {
            
            if (!edituid || edituid === '') {
                console.log(props.getallusers_suc,"props.getallusers_sucprops.getallusers_suc");
                setRiskOwnvalue(props.getallusers_suc.Records[0]._id)
                setRiskRepovalue(props.getallusers_suc.Records[0]._id)
            }
            setArray(props.getallusers_suc.Records.map(items => {
                return {
                    ...items,
                    value: items._id,
                    name: `${items.firstname} ${items.lastname}`
                }
            }))
        }
    }, [props.getallusers_suc])

    useEffect(() => {
        
        console.log(props,"uyuii7777777ttiti");
        
    },[])


    useMemo(() => {
        if (localStorage.getItem('role') === 'admin') {

            if (props.getall_risk_suc) {
                setOptions(props.getall_risk_suc.data.map(items => {
                    return {
                        value: items.project_name,
                        label: items.project_name
                    }
                }))
            }
        }
    }, [props.getall_risk_suc])



    useMemo(() => {
        if (props.edit_risk_suc) {
            if (props.edit_risk_suc.data.iszoho == true) {
                setProjType("Zoho project")
            }
            setProjName(props.edit_risk_suc.data.project_name)
            setProjNum(props.edit_risk_suc.data.project_number)
            setRiskHaz(props.edit_risk_suc.data.risk)
            setRiskId(props.edit_risk_suc.data.riskid)
            setCause(props.edit_risk_suc.data.cause)
            setCntrlOne(props.edit_risk_suc.data.control1)
            setCntrlTwo(props.edit_risk_suc.data.control2)
            setCntrlThree(props.edit_risk_suc.data.control3)
            setBsaofEst(props.edit_risk_suc.data.estimate)
            setLowCost(props.edit_risk_suc.data.lowcost)
            setHighCost(props.edit_risk_suc.data.highcost)
            setDeteRisk(props.edit_risk_suc.data.deterministicrisk)
            setLikelyCost(props.edit_risk_suc.data.likelycost)
            setTrianInv(props.edit_risk_suc.data.trianinv_mcs_js)
            setPageLoader(false)

            setStausValue(props.edit_risk_suc.data.status)
            setCtyValue(props.edit_risk_suc.data.category)
            setProbabilityvalue(props.edit_risk_suc.data.probability)
            let woPer = props.edit_risk_suc.data.probability * 100
            setProbabilitySelectValue({ value: props.edit_risk_suc.data.probability, label: woPer + '%' })
            setIterationvalue(props.edit_risk_suc.data.iterations)
            let date = moment.utc(props.edit_risk_suc.data.risk_date)
            onChange(date._d)
            let realdate = moment.utc(props.edit_risk_suc.data.realisation_date)
            onSelect(realdate._d)
            setRiskRepovalue(props.edit_risk_suc.data.reporter)
            setRiskOwnvalue(props.edit_risk_suc.data.owner)
            setValue({ value: props.edit_risk_suc.data.project_name, label: props.edit_risk_suc.data.project_name });
            setZohoTaskID(props.edit_risk_suc.data.zohotaskid)
            setZohoIssueId(props.edit_risk_suc.data.zohoissueid)
            //New Fields
            setrisksources(props.edit_risk_suc.data.risksource)
            setriskcategories(props.edit_risk_suc.data.riskcatagory)
            setmitigationstatus(props.edit_risk_suc.data.mitigationstatus)
            setmitigationPlannedClosureDate(props.edit_risk_suc.data.mitigationplannedclosure)
            setmitigationActualClosureDate(props.edit_risk_suc.data.mitigationactualclosure)
            setcontingencystatus(props.edit_risk_suc.data.contingencystatus)
            setcontingencyPlannedClosureDate(props.edit_risk_suc.data.contingencyplannedclosuredate)
            setcontingencyActualClosureDate(props.edit_risk_suc.data.contingencyactualclosuredate)
            setactionImplemented(props.edit_risk_suc.data.actionimplemented)
            setclosureDate(props.edit_risk_suc.data.closuredate)
            setremarks(props.edit_risk_suc.data.remarks)
            setimpact(props.edit_risk_suc.data.impact)
            setriskExposure(props.edit_risk_suc.data.riskexposure)
            setrisklevel(props.edit_risk_suc.data.risklevel)
            setFields(props.edit_risk_suc.data.customfield)
            setuserDefinedvalue(props.edit_risk_suc.data.customfieldvalue)
        }
    }, [props.edit_risk_suc])

    return (
        <div className="black">
           {logouloder && <LogoutModal  />}
            <Header />
            <div className="pageContain">
                <Sidebar />
                <div className="contentWrapper">
                    <div className="content riskinput">
                        <Toast animation={false} show={showtoast} className={`toast_msgs ${msgclass}`}>
                            <Toast.Body>
                                <strong className="mr-auto">{toastmsg}</strong>
                            </Toast.Body>
                        </Toast>
                        <MoonLoader css={override} size={80} color={"#16224a"} loading={pageloader} />
                        <div className="pageTitleWrapper">
                            <div className="alert_custom_part">
                                <div className="pageTitleHeading deviceHeading">
                                    <span className="pageTitleHeadingIcon"><FaWpforms /></span>
                                    Risk Input
                                </div>
                            </div>
                        </div>
                        <Modal className="delete_popup" show={show} onHide={handleClose}>
                            <Modal.Body>
                                <FaExclamationCircle />
                                <h3>Are you sure to change number of iterations?</h3>
                                <p>It will affect number of iterations of the other risks in the project.</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                                <Button variant="primary" onClick={onSelectIteration}>Yes, change it!</Button>
                            </Modal.Footer>
                        </Modal>
                        <Form className={pageloader ? "riskForm histograph" : "riskForm"} onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Check type="radio" label="Direct project" defaultChecked name="projtype" id="radio1" value="Direct Project" className="radio_form" onChange={(e) => handleOnChange(e.target.value)} />
                                    {/* <Form.Check type="radio" label="Zoho project" name="projtype" id="radio2"  value="Zoho Project" className="radio_form" onChange={(e) => handleOnChange(e.target.value)} /> */}

                                    <Form.Check type="radio" label="Zoho project" name="projtype" id="radio2" value="Zoho Project"
                                        disabled={!zohoConn || zohoConn == 'false'}
                                        className="radio_form" onChange={(e) => handleOnChange(e.target.value)} />
                                </Col>
                            </Row>
                            <Row>
{/* 
                            <Form.Group className={projectClassname}>
                                        <Form.Label>Project Name *</Form.Label>
                                        <CreatableSelect isClearable isDisabled={isLoading} isLoading={isLoading} onChange={handleChange} onCreateOption={handleCreate} options={options} value={value} />
                                        {isvalidate && value === undefined ? <Form.Label className="validationError">Please select / enter project</Form.Label> : null}
                                    </Form.Group> */}
                                <Col>
                                    <Form.Group className={projectClassname}>
                                        <Form.Label>Project Name *</Form.Label>
                                        <div>
                                            {projtype == "Direct Project" ? <CreatableSelect isClearable isDisabled={isLoading} isLoading={isLoading} onChange={handleChange} onCreateOption={handleCreate} options={options} value={value} placeholder="Select or Create..." /> : <Select isDisabled={isLoading} isLoading={isLoading} onChange={handleChange} onCreateOption={handleCreate} options={options} value={value} />}
                                        </div>
                                        {isvalidate && value === undefined ? <Form.Label className="validationError">Please select / enter project</Form.Label> : null}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Project Number</Form.Label>
                                        <div className="inputWithIcon">
                                            {newproject ? <Form.Control type="text" name="projnum" onChange={handleFieldValue} placeholder="" value={projnum} /> : <Form.Control type="text" disabled name="projnum" onChange={handleFieldValue} placeholder="" value={projnum} />}
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Risk ID *</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="number" name="riskid" onChange={handleFieldValue} placeholder="Pleace enter the number" value={riskid} />
                                        </div>
                                        {isvalidate && riskid === '' ? <Form.Label className="validationError">Please enter risk id</Form.Label> : null}
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row className="risk_sub" >
                                <div className="pageTitleHeading deviceHeading">Risk Details</div>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Risk Description *</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="textarea" className="txt_ara_cls" name="riskhaz" onChange={handleFieldValue} value={riskhaz} />
                                            <span className="txt_dtls">This is used to provide a description of risk</span>
                                        </div>
                                        {isvalidate && riskhaz === '' ? <Form.Label className="validationError">Please enter Risk Description</Form.Label> : null}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Cause</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="textarea" className="txt_ara_cls" name="cause" onChange={handleFieldValue} value={cause} />
                                            <span className="txt_dtls">This cell is used to provide background to the cause of the risk</span>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>



                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Risk Sources</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="select" onChange={onChangeRiskSources} defaultValue={risksources} value={risksources} >
                                                <option value="Requirement stability">Requirement stability</option>
                                                <option value="New terrain – inaccurate estimation">New terrain – inaccurate estimation</option>
                                                <option value="Design">Design</option>
                                                <option value="Technology">Technology</option>
                                                <option value="Aggressive schedule">Aggressive schedule</option>
                                                <option value="Staff and skill">Staff and skill</option>
                                                <option value="Subcontractor capability">Subcontractor capability</option>
                                                <option value="Unstable vendor supplied tools">Unstable vendor supplied tools</option>
                                            </Form.Control>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Risk Categories</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="select" onChange={onChangeRiskCategories} defaultValue={riskcategories} value={riskcategories} >
                                                <option value="Technical Risks">Technical Risks</option>
                                                <option value="Management Risks">Management Risks</option>
                                                <option value="User Risks">User Risks</option>
                                                <option value="Resource Risks">Resource Risks</option>
                                                <option value="Schedule Overrun">Schedule Overrun</option>
                                                <option value="Effort Overrun">Effort Overrun</option>
                                                <option value="Quality Issues">Quality Issues</option>
                                                <option value="Unwanted Product">Unwanted Product</option>
                                                <option value="Staff Attrition">Staff Attrition</option>
                                                <option value="Poor communication with customer">Poor communication with customer</option>
                                                <option value="Possible disruption of operations">Possible disruption of operations</option>
                                            </Form.Control>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Control 3</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="textarea" className="txt_ara_cls" name="cntrlthree" onChange={handleFieldValue} value={cntrlthree} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Basis of Estimate</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="textarea" className="txt_ara_cls" name="bsaofest" onChange={handleFieldValue} value={bsaofest} />
                                            <span className="txt_dtls">This is used to provide the basis of how an estimate for the 'low cost', 'likely cost' and 'highly cost' were determined.</span>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Risk Status *</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="select" onChange={onChangeStatus} defaultValue={stausvalue} value={stausvalue} >
                                                <option value="Open">Open</option>
                                                <option value="InProgress">In Progress</option>
                                                <option value="Mitigated/Resolved">Mitigated/Resolved</option>
                                                <option value="Transfer">Transfer</option>
                                                <option value="Closed">Closed</option>
                                                <option value="Realised">Realised</option>
                                                <option value="Accept">Accept</option>
                                                <option value="Occurred">Occurred</option>
                                            </Form.Control>
                                            <span className="txt_dtls">Realised risks become issues.</span>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="select" onChange={onChangeCategory} defaultValue={ctyvalue}>
                                                <option value="General">General</option>
                                            </Form.Control>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Identified By *</Form.Label>
                                        <div className="inputWithIcon">
                                            <SelectSearch onChange={setRiskRepovalue} value={riskrepovalue} options={userarray} search emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>Not found </div>} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Person Responsible *</Form.Label>
                                        <div className="inputWithIcon">
                                            <SelectSearch onChange={setRiskOwnvalue} value={riskownvalue} options={userarray} search emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>Not found </div>} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <div className="two_date_width">
                                            <Form.Label>Identified Date *</Form.Label>
                                            <div className="inputWithIcon">
                                                <DatePicker onChange={onChange} value={datevalue} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <div className="two_date_width">
                                            <Form.Label>Due/Expected Closure Date</Form.Label>
                                            <div className="inputWithIcon">
                                                <DatePicker onChange={onSelect} value={realisationdate} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="risk_sub" >
                                <div className="pageTitleHeading deviceHeading">Risk Value</div>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Monte Carlo Simulation" defaultChecked={montechecked} onChange={() => { setMonteChecked(!montechecked) }} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Probability *{checkprbval ? <Form.Label className="validationError upr_valid">Please enter numbers in between 1-100</Form.Label> : null}</Form.Label>
                                        <CreatableSelect isClearable onChange={probChange} value={probabilitySelectValue} onInputChange={handleProbChange} options={probabilityOptions} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Impact</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="select" onChange={onChangeImpact} defaultValue={impact} value={impact} >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </Form.Control>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Risk Level</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="text" name="risklevel" disabled value={risklevel} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Risk Exposure </Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="text" name="riskexposure" disabled value={riskExposure} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Low Cost {montechecked ? '*' : null}</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="number" name="lowcost" onChange={handleFieldValue} value={lowcost} placeholder="Pleace enter the number" />
                                            <span className="txt_dtls">Low cost will be used for a Monte Carlo simulation of risk</span>
                                        </div>
                                        {isvalidate && montechecked && lowcost === '' ? <Form.Label className="validationError">Please enter low cost</Form.Label> : null}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>High Cost {montechecked ? '*' : null}</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="number" name="highcost" onChange={handleFieldValue} value={highcost} placeholder="Pleace enter the number" />
                                            <span className="txt_dtls">High cost will be used for Monte Carlo simulation of risk</span>
                                        </div>
                                        {isvalidate && montechecked && highcost === '' ? <Form.Label className="validationError">Please enter high cost</Form.Label> : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Deterministic Risk</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="text" name="deterisk" disabled value={deterisk} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Likely Cost *</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="number" name="likelycost" onChange={handleFieldValue} value={likelycost} placeholder="Pleace enter the number" />
                                            <span className="txt_dtls">Low cost will be used for both Deterministic and Monte Carlo simulation of risk</span>
                                        </div>
                                        {isvalidate && likelycost === '' ? <Form.Label className="validationError">Please enter likely cost</Form.Label> : null}
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Number of Iterations</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="select" onChange={onChangeIteration} defaultValue={iterationvalue} value={iterationvalue}>
                                                <option value="5000">5000</option>
                                                <option value="100000">100000</option>
                                            </Form.Control>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                </Col>
                            </Row>

                            {/* Mitigation Action */}
                            <Row className="risk_sub" >
                                <div className="pageTitleHeading deviceHeading">Mitigation Action</div>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Mitigation Plan *</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="textarea" className="txt_ara_cls" name="cntrlone" onChange={handleFieldValue} value={cntrlone} />
                                            <span className="txt_dtls">This provide a summary of the proposed controls to mitigate the risk from a value for money perspective.</span>
                                            {isvalidate && cntrlone === '' ? <Form.Label className="validationError">Please enter Mitigation Plan</Form.Label> : null}
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Mitigation Status</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="text" name="mitigationstatus" onChange={handleFieldValue} value={mitigationstatus} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <div className="two_date_width">
                                            <Form.Label>Mitigation Planned Closure Date *</Form.Label>
                                            <div className="inputWithIcon">
                                                <DatePicker onChange={setmitigationPlannedClosureDate} value={mitigationPlannedClosureDate} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <div className="two_date_width">
                                            <Form.Label>Mitigation Actual Closure Date</Form.Label>
                                            <div className="inputWithIcon">
                                                <DatePicker onChange={setmitigationActualClosureDate} value={mitigationActualClosureDate} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Contingency Action */}
                            <Row className="risk_sub" >
                                <div className="pageTitleHeading deviceHeading">Contingency Action</div>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                    
                                        <Form.Label>Contingency Plan *</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="textarea" className="txt_ara_cls" name="cntrltwo" onChange={handleFieldValue} value={cntrltwo} />
                                            {isvalidate && cntrltwo === '' ? <Form.Label  className="validationError">Please enter Contingency Plan</Form.Label> : null}
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Contingency Status</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="text" name="contingencystatus" onChange={handleFieldValue} value={contingencystatus} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Contingency Planned Closure Date *</Form.Label>
                                        <div className="inputWithIcon">
                                            <DatePicker onChange={setcontingencyPlannedClosureDate} value={contingencyPlannedClosureDate} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Contingency Actual Closure Date</Form.Label>
                                        <div className="inputWithIcon">
                                            <DatePicker onChange={setcontingencyActualClosureDate} value={contingencyActualClosureDate} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Action Implemented</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="text" name="actionimplemented" onChange={handleFieldValue} value={actionImplemented} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Remarks</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control type="text" name="remarks" onChange={handleFieldValue} value={remarks} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <div className="two_date_width">
                                            <Form.Label>Closure Date *</Form.Label>
                                            <div className="inputWithIcon">
                                                <DatePicker onChange={setclosureDate} value={closureDate} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* user defined fields  */}
                            <div>
                                <Row className="risk_sub" >
                                    <div className="pageTitleHeading deviceHeading">Additional fields</div>
                                </Row>
                            </div>




                            <Row>

                                <Col>
                                    {fields.map((field, idx) => {
                                        return (
                                            <div className="inputWithIcon" key={`${field}-${idx}`}>

                                                <Form.Label>UserDefined Key</Form.Label>
                                                <InputGroup >
                                                    <Form.Control type="text" name="userdefinedkey" onChange={(e) => handleAddfields(idx, e)} value={field.value || ""} />
                                                </InputGroup>
                                            </div>
                                        );
                                    })}
                                </Col>

                                <Col>
                                    {userDefinedvalue.map((userDefinedvalue, idx) => {
                                        return (
                                            <div className="inputWithIcon" key={`${userDefinedvalue}-${idx}`}>
                                                <Form.Label>UserDefined Value</Form.Label>
                                                <InputGroup >
                                                    <Form.Control type="text" name="userdefinedvalue" onChange={(e) => handleAddvalues(idx, e)} value={userDefinedvalue.value || ""} />
                                                    {fields.length !== 1 &&
                                                        <Button type="button" style={{ marginLeft: 20 }} onClick={() => handleRemove(idx)}>
                                                            X
                                                        </Button>}
                                                    {fields.length - 1 === idx && <Button style={{ marginLeft: 20 }} type="button" onClick={() => handleAdd()}>
                                                        +
                                                    </Button>}
                                                </InputGroup>
                                            </div>
                                        );
                                    })}

                                </Col>

                            </Row>





                            {edituid ? <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>TRIANINV_MCS_JS</Form.Label>
                                        <div className="inputWithIcon">
                                            <Form.Control as="textarea" className="txt_ara_cls trianinv" name="trianinv" onChange={handleFieldValue} value={trianinv} />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                </Col>
                            </Row> : null}
                            <Row>
                                <Col>
                                    {hidebtnload ? <Button variant="primary" type="submit" >Submit</Button> :
                                        <Button variant="primary" type="submit" disabled >
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        </Button>}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ riskinputs: {name,logoutLoader,add_risk_suc, add_risk_fail, edit_risk_suc, edit_risk_fail, udt_risk_suc, udt_risk_fail, getall_risk_suc, getall_risk_fail}, users: { getallusers_suc, getallusers_fail } }) => ({
    name,add_risk_suc, add_risk_fail, getallusers_suc, getallusers_fail, edit_risk_suc, edit_risk_fail, udt_risk_suc, udt_risk_fail, getall_risk_suc, getall_risk_fail,logoutLoader
})

const mapDispatchToProps = {
    getusers: usersActions.getallUsers,
    addrisk: riskActions.addrisks,
    getExactrisk: riskActions.editrisk,
    updaterisk: riskActions.updaterisk,
    getProjects: riskActions.getProjectsList
}

export default connect(mapStateToProps, mapDispatchToProps)(Risks);