import React,{memo} from 'react'
import { VegaLite } from 'react-vega'

const GraphComponent = props => {
    return(
    <div className= {props.loading ? 'content histograph' : 'content'} >
        <VegaLite spec={props.spec} />
      </div>
    )
}

export default memo(GraphComponent)