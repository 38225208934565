import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Button, Image, Toast, Modal, Form, Row, Col, Spinner, ResponsiveEmbed } from 'react-bootstrap';
import { FaUserEdit, FaPowerOff, FaBell, FaEllipsisV, FaCog, FaUserAlt } from 'react-icons/fa';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { push } from 'react-router-redux';
// const baseurl = process.env.REACT_APP_BASE_URL;
const baseUrl = 'https://foresightapi.infrahub.co/';
 const baseUrl1 = 'http://localhost:8081/'
// http://localhost:8081/
const axios = require('axios');


const authResult = new URLSearchParams(window.location.search);
const zohoConnStatus = authResult.get('name');
var ZohoConnToast = (zohoConnStatus && zohoConnStatus == 'zoho' ? true : false);
if (ZohoConnToast) {
    localStorage.setItem('zoho_conn', true);
}
var zohoConn = localStorage.getItem('zoho_conn');
export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profile: sessionStorage.getItem('profile'),
            message: 'Zoho connection successfull',
            ZohoConnToast: ZohoConnToast,
            show: false,
            open: false,
            currencyType: localStorage.getItem('currencyType') ? localStorage.getItem('currencyType') : 'USD',
            hidebtnload: true,
        }
        this.logoutClick = this.logoutClick.bind(this)

    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ ZohoConnToast: false });
            var url = document.location.href;
            window.history.pushState({}, "", url.split("?")[0]);
        }, 2000)
    }

    logoutClick = (e) => {
        var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN')
        var data = {
            fcm: sessionStorage.getItem('fcm'),
            email: sessionStorage.getItem('email')
        }
        axios.get(baseUrl + 'index/logout', data, { headers: { authorization: AuthStr } })
            .then(res => {
                sessionStorage.clear();
                localStorage.clear();
                return window.location = '/login';
            }).catch(function (error) {
                console.log(error)
            });
    }

    zohoConnect = (e) => {
        if (zohoConn != 'false') {
            var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN');
            axios.get(baseUrl + 'index/unlinkzoho/' + localStorage.getItem('uid'), { headers: { authorization: AuthStr } })
                .then(response => {
                    localStorage.setItem('zoho_conn', false);
                    this.setState({ message: 'Zoho disconnected successfully', ZohoConnToast: true })
                    setTimeout(() => {
                        this.setState({ ZohoConnToast: false });
                        return window.location = '/dashboard'
                    }, 1000)
                }).catch(function (error) {
                    console.log(error);
                })
        } else {
            var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN');
            axios.get(baseUrl + 'index/zohoauthenticationapi/' + localStorage.getItem('uid'), { headers: { authorization: AuthStr } })
                .then(response => {
                    window.open(response.data.url, '_blank', 'noopener,noreferrer')
                }).catch(function (error) {
                    console.log(error);
                })
        }
    }
    onChangeCurrencyType = (e) => {
        this.setState({ currencyType: e.target.value })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const { currencyType } = this.state
        if (currencyType != '') {
            var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN');
            axios.post(baseUrl + 'index/updatecurrency/' + localStorage.getItem('uid') + '/' + currencyType, { headers: { authorization: AuthStr } })
                .then(response => {
                    localStorage.setItem('currencyType', currencyType);
                    this.setState({ message: response.data.message, ZohoConnToast: true, show: false })
                    setTimeout(() => {
                        this.setState({ ZohoConnToast: false });
                    }, 6000)
                }).catch(function (error) {
                    console.log(error);
                })
        }
    }
    changeCurrSettings = (e) => {
        this.setState({ show: true })
    }
    handleClose = () => {
        this.setState({ show: false })
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    };

    handleClosesss = () => {
        this.setState({ open: false });
    };


    callapitocancel=()=>{
        axios.get(baseUrl +'index/unsubscribestripe/' + localStorage.getItem('uid'))
        .then(response=>{

            console.log("final response",response)
          this.setState({ open: false });
          localStorage.removeItem("uid");
        return window.location = '/login'
        }).catch((error)=>{
           
           console.log({error,message:error.message,data:"something went wrong..."})
           alert("error1234",error)
        })
      }

    render() {
        return (
            <Navbar expand="md" className="header">
                <Navbar.Brand href="/risks">
                    {/* <h1>Foresight</h1> */}

                    <div>
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClosesss}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Cancel subscription"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to cancel your subscription ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClosesss} style={{ backgroundColor: "#0F2869", color: "#ffffff" }} >Cancel</Button>
                                <Button style={{ backgroundColor: "#0F2869", color: "#ffffff" }} onClick={this.callapitocancel} >
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <Image src="foresight.jpg" />

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarToggleTop" className="menuToggleBtn"><FaEllipsisV /></Navbar.Toggle>
                <Navbar.Collapse id="navbarToggleTop">
                    <Toast style={{ 'position': 'absolute', 'margin': '0 auto', 'textAlign': 'center', 'right': 0, 'left': 0, 'border': '2px solid green' }} animation={false} show={this.state.ZohoConnToast} className="zohoSuccessmsg">
                        <Toast.Body>
                            <strong className="mr-auto ">{this.state.message}</strong>
                        </Toast.Body>
                    </Toast>
                    {(zohoConn == 'false' || zohoConn == false) ? <Button variant="default" className="zoholinkBtn" onClick={this.zohoConnect}><FaCog /> Connect Zoho</Button> : <Button variant="default" className="zoholinkBtn" onClick={this.zohoConnect}><FaCog /> Disconnect Zoho</Button>}
                    <Nav style={{ 'marginRight': '20px !important' }} className="" activeKey={window.location.pathname}>
                        <NavDropdown alignRight title={<div><Image src="../public.png" />{localStorage.getItem('fname')}</div>} className="userDropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/myprofile"><FaUserEdit /> My Profile</NavDropdown.Item>
                            {localStorage.getItem('role') === 'admin' ? <NavDropdown.Item onClick={this.handleClickOpen} ><FaCog />Cancel Subscription</NavDropdown.Item> : null}
                            {(zohoConn == 'false' || zohoConn == false) ? null : <NavDropdown.Item onClick={this.changeCurrSettings}><FaCog /> Currency Settings</NavDropdown.Item>}
                            <NavDropdown.Item onClick={this.logoutClick}><FaPowerOff /> Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    {/* Edit currency popup start */}
                    <Modal show={this.state.show} onHide={this.handleClose} >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Currency</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="" onSubmit={this.handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Currency Type </Form.Label>
                                            <div className="inputWithIcon">
                                                <FaUserAlt />
                                                {/* <Select value={this.state.assignOption} onChange={this.handleddlChange} options={this.state.options} /> */}
                                                <Form.Control as="select" name="currencyType" onChange={this.onChangeCurrencyType} defaultValue={this.state.currencyType}>
                                                    <option value="USD">USD-US Dollar</option>
                                                    <option value="INR">INR- Indian Rupee</option>
                                                    <option value="SGD">SGD- Singapore Dollar</option>
                                                    <option value="AUD">AUD- Australian Dollar</option>
                                                    <option value="GBP">GBP-Pound Sterling</option>
                                                    <option value="NZD">NZD- New Zealand Dollar</option>
                                                </Form.Control>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            {this.state.hidebtnload ? <Button className="updatemyprofilebutton" variant="primary" onClick={this.handleSubmit}>Update</Button> :
                                <Button className="updatemyprofilebutton" variant="primary" type="submit" onClick={this.handleSubmit} disabled >
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                </Button>}
                        </Modal.Footer>
                    </Modal>
                    {/* Edit currency popup start */}
                </Navbar.Collapse>
            </Navbar>

        )
    }
}