import { dashboardConstants } from "../actions/constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {
    loading: true,
    getsglpro_req : '',
    getsglpro_suc : '',
    getsglpro_fail : '',
    gettorpro_suc : '',
    gettorpro_fail : '',
    gettorpro_req : ''
} : {};

function dashboard(state = initialState, action) {
    switch (action.type) {
        case dashboardConstants.GETSINGLEPROJECT_REQUEST:
            console.log("request");
            return {
                ...state,
                loading: false,
                getsglpro_req: action.getoneprojectdatareq,
                getsglpro_suc:'',
                getsglpro_fail:''
            };
        case dashboardConstants.GETSINGLEPROJECT_SUCCESS:
            console.log("succee");
            return {
                ...state,
                loading: false,
                getsglpro_suc: action.getoneprojectdata
            };
        case dashboardConstants.GETSINGLEPROJECT_FAILURE:
            console.log("faillllll");
            return {
                ...state,
                loading: false,
                getsglpro_fail : action.getoneprojectdataerror
            };
        case dashboardConstants.GETSINGLETORNADO_REQUEST:
            console.log("single reqq");
            return {
                ...state,
                loading: false,
                gettorpro_req: action.getonetornadodatareq,
                gettorpro_suc:'',
                gettorpro_fail:''
            };
        case dashboardConstants.GETSINGLETORNADO_SUCCESS:
            console.log("succ singleee");
            return {
                ...state,
                loading: false,
                gettorpro_suc: action.getonetornadodata
            };
        case dashboardConstants.GETSINGLETORNADO_FAILURE:
            console.log("1111");
            return {
                ...state,
                loading: false,
                gettorpro_fail : action.getonetornadodataerror
            };
        default:
            return state;
    }
}

export default dashboard;