import {
    riskConstants
} from "../actions/constants";

import {
    STATUS_CHANGED_SUCCESSFULLY
} from "../actions/constants";



let user = JSON.parse(localStorage.getItem("user"));

const initialState = user ? {
    loading: true,
    error : false,
    message: undefined,
    login_err : '',
    add_risk_suc : '',
    add_risk_fail : '',
    tbl_rsk_suc : '',
    tbl_rsk_fail : '',
    edit_risk : '',
    edit_risk_suc : '',
    edit_risk_fail : '',
    udt_risk_suc : '',
    udt_risk_fail : '',
    getall_risk_suc : '',
    getall_risk_fail : '',
    getrisksettings_req : '',
    getrisksettings_suc:'',
    getrisksettings_fail:'',
    logoutLoader: false,
   
} : {};

function riskinputs(state = initialState, action) { 
    switch (action.type) {
        case STATUS_CHANGED_SUCCESSFULLY:
            console.log(action,"ttt666666666tttt");
            return {
                ...state,
                logoutLoader: true
            }
        case riskConstants.ADDRISK_REQUEST:
            return {
                ...state,
                loading: false,
                add_risk_suc : '',
                add_risk_fail : ''
            };
        case riskConstants.ADDRISK_SUCCESS:
            return {
                ...state,
                loading: false,
                add_risk_suc: action.riskadd
            };
        case riskConstants.ADDRISK_FAILURE:
            return {
                ...state,
                loading: false,
                add_risk_fail : action.riskerror
            };
        case riskConstants.GETTABLERISK_REQUEST:
            return {
                ...state,
                loading: false,
                tbl_rsk_suc : '',
                tbl_rsk_fail : ''
            };
        case riskConstants.GETTABLERISK_SUCCESS:
            return {
                ...state,
                loading: false,
                tbl_rsk_suc: action.getrisksuc
            };
        case riskConstants.GETTABLERISK_FAILURE:
            return {
                ...state,
                loading: false,
                tbl_rsk_fail : action.getriskerror
            };
        case riskConstants.EDITRISK_REQUEST:
            return {
                ...state,
                loading: false,
                edit_risk_suc : '',
                edit_risk_fail : ''
            };
        case riskConstants.EDITRISK_SUCCESS:
            return {
                ...state,
                loading: false,
                edit_risk_suc: action.editrisksuc
            };
        case riskConstants.EDITRISK_FAILURE:
            return {
                ...state,
                loading: false,
                edit_risk_fail : action.editriskerror
            };
        case riskConstants.UPDATERISK_REQUEST:
            return {
                ...state,
                loading: false,
                udt_risk_suc : '',
                udt_risk_fail : ''
            };
        case riskConstants.UPDATERISK_SUCCESS:
            return {
                ...state,
                loading: false,
                udt_risk_suc: action.riskudt
            };
        case riskConstants.UPDATERISK_FAILURE:
            return {
                ...state,
                loading: false,
                udt_risk_fail : action.riskudterror
            };
        case riskConstants.GETALLPROJECT_REQUEST:
            return {
                ...state,
                loading: false,
                getall_risk_suc : '',
                getall_risk_fail : ''
            };
        case riskConstants.GETALLPROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                getall_risk_suc: action.getallprojects
            };
        case riskConstants.GETALLPROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                getall_risk_fail : action.getallprojectserror
            };
        case riskConstants.GETRISKSETTINGS_REQUEST:
            return {
                ...state,
                loading: false,
                getrisksettings_suc:'',
                getrisksettings_fail:'',
                getrisksettings_req: action.getrisksettingdetails
            };
        case riskConstants.GETRISKSETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                getrisksettings_suc: action.getallsettings
            };
        case riskConstants.GETRISKSETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                getrisksettings_fail : action.getallsettingserror
            };
        default:
            return state;
    }
}

export default riskinputs;