import { dashboardConstants } from "./constants";
const axios = require("axios");
// const baseUrl = process.env.REACT_APP_BASE_URL
const baseUrl = 'https://foresightapi.infrahub.co/'
 const baseUrl1 = 'http://localhost:8081/'

const header ={header:{"x-access-token":localStorage.getItem('token')}}


function getProjectData(name) {
  return dispatch => {
    dispatch(request({}));
    console.log(localStorage.getItem('companyid'),name,"sdkjsdkjdkjsd")
    axios.get(baseUrl+'risks/histogram/'+ localStorage.getItem('companyid')+'/'+name).then(function(response) {
     console.log(response,"responseresponse");
      if(response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error,"**********************");
      dispatch(failure(error));
    });
  };
  
  function request(getoneprojectdatareq) {
      return { type: dashboardConstants.GETSINGLEPROJECT_REQUEST, getoneprojectdatareq };
  }
  function success(getoneprojectdata ) {
      return { type: dashboardConstants.GETSINGLEPROJECT_SUCCESS, getoneprojectdata };
  }
  function failure(getoneprojectdataerror) {
      return { type: dashboardConstants.GETSINGLEPROJECT_FAILURE, getoneprojectdataerror };
  }
}

function getTornadoData(name, meanvalue) {
  return dispatch => {
    dispatch(request({}));
    console.log(name, meanvalue,"---");
    axios.get(baseUrl+'risks/tornado/'+ localStorage.getItem('companyid')+'/'+name + '/' + meanvalue).then(function(response) {
      if (response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
        console.log(response.data,'11111111111');
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error,"12232423*******ewfwd***************");
      dispatch(failure(error));
    });
  };
  
  function request(getonetornadodatareq) {
      return { type: dashboardConstants.GETSINGLETORNADO_REQUEST, getonetornadodatareq };
  }
  function success(getonetornadodata ) {
      return { type: dashboardConstants.GETSINGLETORNADO_SUCCESS, getonetornadodata };
  }
  function failure(getonetornadodataerror) {
      return { type: dashboardConstants.GETSINGLETORNADO_FAILURE, getonetornadodataerror };
  }
}

export const dashboard = {
    getProjectData, getTornadoData
};
