import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "../reducers"

const appReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return rootReducer(state, action);
};

const store = createStore(
  appReducer,
  applyMiddleware(thunk, logger)
);

export default store;
