import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Card, Form, Toast } from "react-bootstrap";
import { FaChartBar } from "react-icons/fa";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import { riskActions } from '../../actions/riskinput'
import { dashboard } from '../../actions/dashboard'
import { VegaLite } from 'react-vega'
import SelectSearch from 'react-select-search'
import { css } from "@emotion/core";
import MoonLoader from "react-spinners/MoonLoader";
import { useHistory } from "react-router-dom";
// const axios = require('axios');
import axios from 'axios';

import LogoutModal from '../../components/Cancelsubscription'
// const baseurl = process.env.REACT_APP_BASE_URL;
const baseUrl = 'https://foresightapi.infrahub.co/';
const baseUrl1 = 'http://localhost:8081/'
var zohoConn = localStorage.getItem('zoho_conn');

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const currencyType = localStorage.getItem('currencyType');

const Dashboard = props => {
    const history = useHistory()
    const [riskownvalue, setRiskOwnvalue] = useState('')
    const [userarray, setArray] = useState([{ value: '', name: '' }]);
    const [spec, setSpec] = useState('');
    const [totalDeterminstic, setTotalDeterminstic] = useState('')
    const [pageloader, setPageLoader] = useState(false)
    const [totalp10, setTotalP10] = useState('')
    const [totalp50, setTotalP50] = useState('')
    const [totalp90, setTotalP90] = useState('')
    const [tornado, setTornado] = useState('');
    const [pnumber, setPnumber] = useState('');
    const [projtype, setProjtype] = useState()
    const [showtoast, setShowToast] = useState(false);
    const [toastmsg, setToastMsg] = useState('');
    const [showGraph, setShowGraph] = useState(false);
    const [projname, setProjname] = useState('');
    const [openrisks, setOpenRisks] = useState(0);
    const [closedrisks, setClosedRisks] = useState(0);
    const [realisedrisks, setRealisedRisks] = useState(0);
    const [logouloder, setlogouloder] = useState(false)


    const getApiTest = async () => {
        try {
            let valueTest = await axios.get(baseUrl+'risks/projects/'+ localStorage.getItem('companyid'),{headers:{"x-access-token":localStorage.getItem('token')}})
            console.log(valueTest,"valueTestvalueTest");
            if (valueTest.data.status == 5) {
                setlogouloder(true)
            }

        } catch (error) {
            console.log({ error: error.message })
        }
    }

    useEffect(() => {
        getApiTest()
        if (localStorage.getItem('uid') === null) {
            return window.location = '/';
        } else {
            props.getProjects()

        }
    }, [])

    useEffect(() => {
        if (localStorage.getItem('role') === 'admin') {
            if (props.getall_risk_suc) {
                if (props.getall_risk_suc.data.length > 0) {
                    props.getProjectData(props.getall_risk_suc.data[0].project_name)
                    props.getTornadoData(props.getall_risk_suc.data[0].project_name)
                    setPageLoader(true)
                    setPnumber(props.getall_risk_suc.data[0]._id)
                    setArray(props.getall_risk_suc.data.map(items => {
                        return {
                            value: items.project_name,
                            name: items.project_name,
                            id: items._id
                        }
                    }))
                }
            }
        }
        // else{
        //     axios.get(`http://localhost:8081/user/testapi?role=${localStorage.getItem('role')}&companyId=${localStorage.getItem('companyid')}&type=directProject`).then(function (response) {
        //     setArray(response.data.list.map((items, index) => {
        //         return {
        //             value: items.label,
        //             name: items.label
        //         }
        //     }))
        // })
        // }

    }, [props.getall_risk_suc])

    const convertToReqFormat = (array) => {
        return new Promise((resolve, reject) => {
            let reqFormat = array.map(values => {
                return {
                    u: values
                }
            })
            resolve(reqFormat)
        })
    }

    const convertTornadoFormat = (array) => {
        // debugger
        return new Promise((resolve, reject) => {
            let reqFormat = array.map((values, index) => {
                if (index < 9) {
                    return {
                        max: Math.round(values.max),
                        mean: Math.round(values.mean),
                        min: Math.round(values.min),
                        res: Math.round(values.res),
                        // mean : totalp50,
                        risk: 0 + `${index + 1}) ${values.riskid}`
                    }
                } else {
                    return {
                        max: Math.round(values.max),
                        mean: Math.round(values.mean),
                        min: Math.round(values.min),
                        res: Math.round(values.res),
                        // mean : totalp50,
                        risk: `${index + 1}) ${values.riskid}`
                    }
                }
            })
            resolve(reqFormat)
        })
    }

    const roundToThree = (num) => {
        return +(Math.round(num + "e+3") + "e-3");
    }

    useMemo(() => {
        if (props.getsglpro_suc) {
            if (props.getsglpro_suc.data === 'No data found') {
                setShowGraph(true)
                setPageLoader(false)
            } else {
                setShowGraph(false)
                var str = JSON.parse(props.getsglpro_suc.data);
                convertToReqFormat(str).then(data => {
                    // if(riskownvalue !== ''){
                    //     props.getTornadoData(riskownvalue)
                    // }
                    setSpec({
                        "title": "Histogram Plot for Total Risk",
                        "width": 555,
                        "height": 400,
                        "padding": 25,
                        'signals': [
                            {
                                'name': 'hover',
                                "on": [{ "events": "mousemove" }]
                            }
                        ],
                        "data": {
                            "values": data
                        },
                        "layer": [{
                            "mark": { "type": "bar", "tooltip": true },
                            "encoding": {
                                "x": { "field": "u", "bin": true, "title": "Total Risk Cost" },
                                "y": { "aggregate": "count", "title": "Probability" },
                                "color": { "value": "#0d2869" },
                            }
                        }, {
                            "mark": { "type": "rule", "tooltip": true },
                            "encoding": {
                                "x": { "aggregate": "mean", "field": "u" },
                                "color": { "value": "#000" },
                                "size": { "value": 5 }
                            }
                        }]
                    })
                })
                setTotalDeterminstic(props.getsglpro_suc.totalDeterministicrisk)
                if (props.getsglpro_suc.iterations === 5000) {
                    setTotalP50(Math.round(props.getsglpro_suc.projsum / props.getsglpro_suc.iterations))
                } else {
                    setTotalP50(Math.round(props.getsglpro_suc.projsum / props.getsglpro_suc.iterations))
                }
                if (riskownvalue !== '') {
                    props.getTornadoData(riskownvalue, roundToThree(props.getsglpro_suc.projsum / props.getsglpro_suc.iterations))
                } else {
                    props.getTornadoData(props.getall_risk_suc.data[0].project_name, roundToThree(props.getsglpro_suc.projsum / props.getsglpro_suc.iterations))
                }
            }
        }
    }, [props.getsglpro_suc])


    useMemo(() => {

        if (props.gettorpro_suc) {
            setOpenRisks(props.gettorpro_suc.openrisks)
            setClosedRisks(props.gettorpro_suc.closedrisks)
            setRealisedRisks(props.gettorpro_suc.realisedrisks)
            convertTornadoFormat(props.gettorpro_suc.sortedrisks).then(data => {
                setTornado({
                    "title": "Tornado Plot for Total Risk",
                    "width": 555,
                    "height": 363,
                    "padding": 25,
                    "data": {
                        "values": data
                    },
                    "layer": [
                        {
                            "mark": { "type": "bar", "tooltip": true },
                            "encoding": {
                                "y": { "field": "risk", "type": "ordinal", "title": "Risk ID" },
                                "x": { "field": "min" },
                                "color": { "value": "#0d2869" },
                                "size": { "value": 20 },
                                "x2": { "field": "max" }
                            }
                        },
                        {
                            "mark": { "type": "rule", "color": "#0d2869", "tooltip": true },
                            "encoding": {
                                "x": { "field": "mean", "title": "Conditional Mean Value" },
                                "color": { "value": "#000" },
                                "size": { "value": 5 }
                            }
                        }
                    ]
                })
            })
            setPageLoader(false)
        }
    }, [props.gettorpro_suc])

    const setData = (data) => {
        props.getProjectData(data)
        let projnumber = pnumber;
        userarray.filter(function (id) {
            if (id.value == data) { projnumber = id.id }
        })
        setPnumber(projnumber)
        setProjname(data)
        setRiskOwnvalue(data)
        setPageLoader(true)
    }

    const updateBudgetValue = (budgetVal) => {

        setPageLoader(true)
        var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN');
        axios.post(baseUrl + 'index/updatebudgetvalue/' + localStorage.getItem('uid') + '/' + pnumber + '/' + budgetVal, { headers: { authorization: AuthStr } })
            .then(response => {
                setPageLoader(false)
                setShowToast(true)
                setToastMsg('Commercial budget amount updated successfully ' + projname)
                removeToast()
            }).catch(function (error) {
                console.log(error);
            })
    }

    const removeToast = () => {
        setTimeout(() => {
            setShowToast(false)
            setToastMsg('')
        }, 5000)
    }

    const handleNewView = (...view) => {
      
        setTotalP10(roundToThree(Math.round(view[0]._signals.layer_0_bin_maxbins_10_u_extent.value[0])))
        setTotalP90(roundToThree(Math.round(view[0]._signals.layer_0_bin_maxbins_10_u_extent.value[1])))
    };

    const onChangeStatus = (e) => {
        setProjtype(e.target.value)


        if (e.target.value == 'direct') {
            if (localStorage.getItem('role') === 'admin') {
                props.getProjects()
            }
            // else{
            //     axios.get(`http://localhost:8081/user/testapi?role=${localStorage.getItem('role')}&companyId=${localStorage.getItem('companyid')}&type=directProject`).then(function (response) {
            //         setArray(response.data.list.map((items, index) => {
            //             return {
            //                 value: items.label,
            //                 name: items.label
            //             }
            //         }))
            //     })
            // }

        } else {
            if (localStorage.getItem('role') === 'admin') {
                setPageLoader(true)
                var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN')
                axios.get(baseUrl + 'index/zohoprojects/' + localStorage.getItem('uid'), { headers: { authorization: AuthStr } })
                    .then(response => {
                        setArray(response.data.data.projects.map(items => {
                            return {
                                value: items.name,
                                name: items.name,
                                id: items.id_string
                            }
                        }))
                        // setRiskOwnvalue('')
                        setData(response.data.data.projects[0].name)
                        setProjname(response.data.data.projects[0].name)
                        setPnumber(response.data.data.projects[0].id_string)
                        setPageLoader(false)

                    }).catch(function (error) {
                        console.log(error);
                    })
            }
            // else{
            //     axios.get(`http://localhost:8081/user/testapi?role=${localStorage.getItem('role')}&companyId=${localStorage.getItem('companyid')}&type=zohoProject`).then(function (response) {
            //         setArray(response.data.list.map((items, index) => {

            //             return {
            //                 value: items.label,
            //                 name: items.label
            //             }
            //         }))


            //     })
            // }

        }
    }
    console.log(totalp10,"ooo1134");
    return (
        <div className="black">
            <Toast style={{ 'position': 'absolute', 'margin': '0 auto', 'textAlign': 'center', 'right': 0, 'top': 20, 'left': 0, 'border': '2px solid green' }} animation={false} show={showtoast} className="zohoSuccessmsg">
                <Toast.Body>
                    <strong className="mr-auto ">{toastmsg}</strong>
                </Toast.Body>
            </Toast>
            {logouloder && <LogoutModal />}
            <Header />
            <div className="pageContain">
                <Sidebar />

                <div className="contentWrapper for_modal">
                    <MoonLoader css={override} size={80} color={"#16224a"} loading={pageloader} />
                    <div className={pageloader ? "content riskinput histograph" : "content riskinput"}>

                        <div className="dashb_select">
                            <div className="pageTitleHeading">
                                <span className="pageTitleHeadingIcon"><FaChartBar />
                                </span>
                                Dashboard
                            </div>
                            <div style={{ 'marginLeft': '0px !important', 'maxWidth': '10% !important' }} className="float_select ">Project Type</div>
                            <div style={{ 'minWidth': 250 }} className="float_select">
                                <Form.Control as="select" onChange={onChangeStatus} defaultValue={projtype} value={projtype} >
                                    <option value="direct">Direct Projects</option>
                                    {(zohoConn == 'false' || zohoConn == false) ? <option value="zoho">Zoho Projects</option> : <option value="zoho">Zoho Projects</option>}
                                </Form.Control>
                            </div>
                            <div style={{ 'marginLeft': '0px !important', 'maxWidth': '10% !important' }} className="float_select ">Project Name</div>
                            <div style={{ 'minWidth': 250 }} className="float_select">
                                <SelectSearch onChange={setData} value={riskownvalue} options={userarray} search emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>Not found </div>} />
                            </div>
                        </div>
                        {showGraph ? <h1 className='noDataFound'>No data found</h1> : <><div className="entire_card">
                            <Card bg="primary" text="primary" style={{ width: '14rem', 'minHeight': '150px' }} className="mb-2" >
                                <Card.Header className='height70'>Total Deterministic Risk</Card.Header>
                                <Card.Body>
                                    <Card.Title>{totalDeterminstic}<span className="currencyTypeTexts">{currencyType != 'undefined' ? currencyType : ''}</span></Card.Title>
                                    {projtype == 'zoho' ? <Card.Text onClick={(e) => updateBudgetValue(totalDeterminstic)}>Sync
                                    </Card.Text> : <></>}
                                </Card.Body>
                            </Card>
                            <Card bg="secondary" text="secondary" style={{ width: '10rem', 'minHeight': '150px' }} className="mb-2" >
                                <Card.Header className='height70'>P10</Card.Header>
                                <Card.Body>
                                    <Card.Title>{totalp10}<span className="currencyTypeTexts">{currencyType != 'undefined' ? currencyType : ''}</span></Card.Title>
                                    {projtype == 'zoho' ? <Card.Text onClick={(e) => updateBudgetValue(totalp10)}>Sync
                                    </Card.Text> : <></>}
                                </Card.Body>
                            </Card>
                            <Card bg="success" text="success" style={{ width: '11rem', 'minHeight': '150px' }} className="mb-2" >
                                <Card.Header className='height70'>P50</Card.Header>
                                <Card.Body>
                                    <Card.Title>{totalp50}<span className="currencyTypeTexts">{currencyType != 'undefined' ? currencyType : ''}</span></Card.Title>
                                    {projtype == 'zoho' ? <Card.Text onClick={(e) => updateBudgetValue(totalp50)}>Sync
                                    </Card.Text> : <></>}
                                </Card.Body>
                            </Card>
                            <Card bg="dark" text="dark" style={{ width: '11rem', 'minHeight': '150px' }} className="mb-2" >
                                <Card.Header className='height70'>P90</Card.Header>
                                <Card.Body>
                                    <Card.Title>{totalp90}<span className="currencyTypeTexts">{currencyType != 'undefined' ? currencyType : ''}</span></Card.Title>
                                    {projtype == 'zoho' ? <Card.Text onClick={(e) => updateBudgetValue(totalp90)}>Sync
                                    </Card.Text> : <></>}
                                </Card.Body>
                            </Card>
                            <Card bg="info" text="info" style={{ width: '10rem', 'minHeight': '150px' }} className="mb-2" >
                                <Card.Header className='height70'>No Of Risks</Card.Header>
                                <Card.Body>
                                    <Card.Title>{openrisks}</Card.Title>
                                    {projtype == 'zoho' ? <Card.Text>&nbsp; </Card.Text> : <></>}
                                </Card.Body>
                            </Card>
                            <Card bg="danger" text="danger" style={{ width: '10rem', 'minHeight': '150px' }} className="mb-2" >
                                <Card.Header className='height70'>No Of Closed Risks</Card.Header>
                                <Card.Body>
                                    <Card.Title>{closedrisks}</Card.Title>
                                    {projtype == 'zoho' ? <Card.Text>&nbsp; </Card.Text> : <></>}
                                </Card.Body>
                            </Card>
                            <Card bg="warning" text="warning" style={{ width: '10rem', 'minHeight': '150px' }} className="mb-2" >
                                <Card.Header className='height70'>No Of Realised Risks</Card.Header>
                                <Card.Body>
                                    <Card.Title>{realisedrisks}</Card.Title>
                                    {projtype == 'zoho' ? <Card.Text> &nbsp;</Card.Text> : <></>}
                                </Card.Body>
                            </Card>
                        </div>
                            {spec ? <VegaLite spec={spec} onNewView={handleNewView} /> : null}
                            {tornado ? <VegaLite spec={tornado} onNewView={handleNewView} /> : null}</>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ riskinputs: { getall_risk_suc, getall_risk_fail }, dashboard: { getsglpro_suc, getsglpro_fail, gettorpro_suc, gettorpro_fail } }) => ({
    getall_risk_suc, getall_risk_fail, getsglpro_suc, getsglpro_fail, gettorpro_suc, gettorpro_fail
})

const mapDispatchToProps = {
    getProjects: riskActions.getProjectsList,
    getProjectData: dashboard.getProjectData,
    getTornadoData: dashboard.getTornadoData
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);