import { userConstants } from "./constants";
const axios = require("axios");
// const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = 'https://foresightapi.infrahub.co/'
const baseUrl = 'https://foresightapi.infrahub.co/'
const baseUrl1= 'http://localhost:8081/'
// https://foresightapi.infrahub.co/

function login(email, password) { 

  return dispatch => {
    dispatch(request({ email }));
    axios
      .post(baseUrl + "index/login", {
        email: email,
        password: password
      })
      .then(function(response) { 
        if (response.data.status !== undefined && response.data.status === 1) {
          localStorage.setItem('subscriptionEndDate', response.data.user.subscriptionEndDate);
          localStorage.setItem('uid', response.data.user._id);
          localStorage.setItem('fname', response.data.user.firstname);
          localStorage.setItem('lname', response.data.user.lastname);
          localStorage.setItem('email', response.data.user.email);
          localStorage.setItem('phoneno', response.data.user.phoneno);
          localStorage.setItem('role', response.data.user.role);
          localStorage.setItem('companyid', response.data.user.companyId);
          localStorage.setItem('zoho_conn', response.data.user.zoho_conn);
          localStorage.setItem('currencyType', response.data.user.currencyType);
          localStorage.setItem('token', response.data.token);
          dispatch(success(response.data));
        } else {
          let message = response.data.message
            ? response.data.message
            : "Incorrect credentials";
          dispatch(failure(message));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function registration(firstname, lastname, email, phoneno, password, token, country, quantity) {
      let body = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phoneno: phoneno,
        password: password,
        token: token,
        country: country,
        quantity:quantity
      }
      // console.log("test body before in action",body);
    return dispatch => { 
      dispatch(request({ email }));
      axios
        .post(baseUrl + "index/signup",body)
        .then(function(response) {
          console.log(response,"testing the response");
          if (response.data.status !== undefined && response.data.status === 1) {
            dispatch(success(response.data));
          } else {
            let message = response.data.msg
              ? response.data.msg
              : "Incorrect credentials";
            dispatch(failure(message));
          }
        })
        .catch(function(error) {
          console.log({error:error.message},"waseeeeem");
          dispatch(failure(error));
        });
    };
  
    function request(registeruser) {
      return { type: userConstants.REGISTRATION_REQUEST, registeruser };
    }
    function success(registeruser) {
      return { type: userConstants.REGISTRATION_SUCCESS, registeruser };
    }
    function failure(registererror) {
      return { type: userConstants.REGISTRATION_FAILURE, registererror };
    }
}

function forgotpwd(email) { 
  console.log(email,'email;')
    return dispatch => {
        dispatch(request({ email }));
        axios
        .post(baseUrl + "user/forgotpassword", {
          email: email
        })
        .then(function(response) {
          console.log(response,"dfsdadafa")
          if (response.data.status !== undefined && response.data.status === 1) {
            dispatch(success(response.data));
          } else {
            let message = response.data.message
              ? response.data.message
              : "Incorrect credentials";
            dispatch(failure(message));
          }
        })
        .catch(function(error) {
          console.log(error,"forgot error");
          dispatch(failure(error));
        });
    };
  
    function request(resetuser) {
      return { type: userConstants.RESET_REQUEST, resetuser };
    }
    function success(resetuser) {
      return { type: userConstants.RESET_SUCCESS, resetuser };
    }
    function failure(reseterror) {
      return { type: userConstants.RESET_FAILURE, reseterror };
    }
}

export const userActions = {
    login, registration, forgotpwd
};
