import React, { useState, useMemo } from 'react'
import { Form, Tabs, Tab, Row, Col, Button, Spinner, Toast, Image, Card, Modal } from 'react-bootstrap'
import { FaUserAlt, FaLock, FaPhoneAlt, FaEnvelopeOpenText, FaEye, FaEyeSlash, FaHome } from 'react-icons/fa'
import { connect } from 'react-redux'
import { userActions } from '../../actions/authentication'
import { useHistory } from "react-router-dom";
import StripeCheckout from 'react-stripe-checkout';

const Authentication = props => {
  // const Stripe_PK = "pk_test_1niWNqOgLUbOBZBXitw10gaK003s4WoWoU"//mi3
  // pk_live_kpwCVyp1MxieT4eI5QEV4U6Q002lNt0uPN
  //pk_test_pH18LThoCMLZV8sBVRmCDxzp00X7aWRTZg

  const Stripe_PK="pk_live_kpwCVyp1MxieT4eI5QEV4U6Q002lNt0uPN"//client
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState('Login');
  const [loginemail, setloginemail] = useState('');
  const [loginpassword, setloginpassword] = useState('');
  const [regfirstname, setRegFirstname] = useState('');
  const [reglastname, setReglastname] = useState('');
  const [regemail, setRegemail] = useState('');
  const [regphoneno, setRegphoneno] = useState('');
  const [regpassword, setRegpassword] = useState('');
  const [regconfpass, setRegconfpass] = useState('');
// new update
  const [quantity,setRegsubquantity]=useState('')
//

  const [forgotpwd, setForgotPwd] = useState('');
  const [isvaildate, setIsvaildate] = useState(false);
  const [emailvalidate, setEmailvalidate] = useState(false);
  const [hidebtnload, setHideBtnLoad] = useState(true);
  const [pwdlength, setPwdlength] = useState(false);
  const [pwdmismatch, setPwdMismatch] = useState(false);
  const [showtoast, setShowToast] = useState(false);
  const [toastmsg, setToastMsg] = useState('');
  const [msgclass, setMsgClass] = useState('');
  const [showpwd, setShowPwd] = useState(false);
  const [showPpwd, setShowPPwd] = useState(false);
  const [showCPpwd, setShowCPPwd] = useState(false);
  const [choosePlan, setChoosePlan] = useState('');
  const [show, setShow] = useState(false);
  const [subscription, setSubscription] = useState('');

  const handleFieldValue = (e) => {
    if (selectedTab === 'Login') {
      switch (e.target.name) {
        case 'loginemail':
          return setloginemail(e.target.value)
        case 'loginpassword':
          return setloginpassword(e.target.value)
        default:
          return setRegemail('')
      }
    } else if (selectedTab === 'Register') {
      switch (e.target.name) {
        case 'regfirstname':
          return setRegFirstname(e.target.value)
        case 'reglastname':
          return setReglastname(e.target.value)
        case 'regemail':
          return setRegemail(e.target.value)
        case 'regphoneno':
          return setRegphoneno(e.target.value)
        case 'regpassword':
          return setRegpassword(e.target.value)
        case 'regconfpass':
          return setRegconfpass(e.target.value)
        case 'quantity':
          return setRegsubquantity(e.target.value)
        default:
          return setRegemail('')
      }
    } else if (selectedTab === 'ForgotPassword') {
      switch (e.target.name) {
        case 'forgotpwd':
          return setForgotPwd(e.target.value)
        default:
          return setRegemail('')
      }
    }
  }

  const toggleShow = () => {
    setShowPwd(!showpwd)
  }

  const handleClick = (e) => {
    if (e === 'new') {
      setShowPPwd(!showPpwd)
    } else if (e === 'confirm') {
      setShowCPPwd(!showCPpwd)
    }
  }

  const emailvalidation = (email) => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(email)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (selectedTab === 'Login') {
      setIsvaildate(true)
      if (loginemail && loginpassword) {
        if (emailvalidation(loginemail)) {
          setHideBtnLoad(false)
          setEmailvalidate(false)
          props.getlogin(loginemail, loginpassword)
        } else {
          setEmailvalidate(true)
        }
      }
    } else if (selectedTab === 'Register') {
      setIsvaildate(true)
      if (regfirstname && regemail && regphoneno && regpassword && regconfpass && quantity) {
        if (emailvalidation(regemail)) {
          setEmailvalidate(false)
          if (regpassword.length > 7) {
            setPwdlength(false)
            if (regpassword === regconfpass) {
              setPwdMismatch(false)
              setHideBtnLoad(false)
              handleShow(true)
               //props.getregister(regfirstname, reglastname, regemail, regphoneno, regpassword)
            } else {
              setPwdMismatch(true)
            }
          } else {
            setPwdlength(true)
          }
        } else {
          setEmailvalidate(true)
        }
      }
    } else if (selectedTab === 'ForgotPassword') {
      setIsvaildate(true)
      if (forgotpwd) {
        if (emailvalidation(forgotpwd)) {
          setEmailvalidate(false)
          setHideBtnLoad(false)
          props.fgtpwd(forgotpwd)
        } else {
          setEmailvalidate(true)
        }
      }
    }
  }

  const handleSelect = (eventKey) => {
    setIsvaildate(false)
    setSelectedTab(eventKey)
  }

  const removeToast = () => {
    setTimeout(() => {
      setShowToast(false)
      setToastMsg('')
    }, 5000)
  }

  useMemo(() => {
    if (props.user) {
      setShowToast(false)
      setToastMsg('')
      setHideBtnLoad(true)
      history.push('/dashboard')
    }
  }, [props.user])

  useMemo(() => {
    if (props.login_err) {
      setShowToast(true)
      setToastMsg(props.login_err)
      setHideBtnLoad(true)
      setMsgClass('error')
      removeToast()
    }
  }, [props.login_err])

  useMemo(() => {
    if (props.reg_user_suc) {
      setSelectedTab('Login')
      setShowToast(true)
      setToastMsg(props.reg_user_suc.msg)
      setHideBtnLoad(true)
      setMsgClass('success')
      setIsvaildate(false)
      setRegFirstname('')
      setReglastname('')
      setRegemail('')
      setRegphoneno('')
      setRegsubquantity('')
      setRegpassword('')
      setRegconfpass('')
      removeToast()
    }
  }, [props.reg_user_suc], selectedTab)

  useMemo(() => {
    if (props.reg_user_fail) {
      setShowToast(true)
      setToastMsg(props.reg_user_fail)
      setHideBtnLoad(true)
      setMsgClass('error')
      setIsvaildate(false)
      removeToast()
    }
  }, [props.reg_user_fail])

  useMemo(() => {
    if (props.reset_user_suc) {
      console.log(props.reset_user_suc.message)
      setShowToast(true)
      setToastMsg(props.reset_user_suc.message)
      setHideBtnLoad(true)
      setMsgClass('success')
      setIsvaildate(false)
      removeToast()
    }
  }, [props.reset_user_suc])

  useMemo(() => {
    if (props.reset_user_fail) {
      console.log(props.reset_user_fail)
      setShowToast(true)
      setToastMsg(props.reset_user_fail)
      setHideBtnLoad(true)
      setMsgClass('error')
      setIsvaildate(false)
      removeToast()
    }
  }, [props.reset_user_fail])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSelectPlan = (ev) => {
    setChoosePlan(ev.target.defaultValue);
  }
  const proceedRegistration = (token) => {
    setShow(false)
    props.getregister(regfirstname, reglastname, regemail, regphoneno, regpassword, token, subscription ,quantity)
    setChoosePlan('')
    setSubscription('')
    //subscription, check which subscription has been choosen, after registration, subscribe the user. 
  }

  const onCountrySelected = (event) => {
    setSubscription(event)
  }


  return (
    <div className="loginContain">
      <div className="main_bg loginFormContain">
        <div className="abv_img_alg">
          <Image src="foresight.jpg" />
        </div>
        <p className="login_desc">Foresight process uses a Monte Carlo simulation and is run on a regular basis to "control" the commercial risk of a project or of any implemented changes. </p>
        <Toast style={{ color: "black" }} animation={false} show={showtoast} className={`toast_msgs ${msgclass}`}>
          <Toast.Body>
            <strong className="mr-auto">{toastmsg}</strong>
          </Toast.Body>
        </Toast>
        {/* Subscription modal */}
        <Modal size="l" style={{ textAlign: 'center', minHeight: '300px' }}
          aria-labelledby="contained-modal-title-vcenter"
          centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Subscribe to Register</Modal.Title>
          </Modal.Header>
          <Modal.Body className="showGrid" style={{ padding: '4rem' }}>

            <StripeCheckout
              stripeKey={Stripe_PK}
              token={proceedRegistration}
              name="Subscription"
              currency={subscription === "Australia" ? 'AUD' : subscription === "INDIA" ? 'INR' : subscription === "UK" ? 'GBP' : subscription === "USA" ? 'USD' : 'USD'}
              amount={subscription === "Australia" ? 5 * 100 : subscription === "INDIA" ? 200 * 100 : subscription === "UK" ? 3 * 100 : subscription === 4 * 100 ? 'USD' : 0}
              email={regemail}
              billingAddress={true}
            >
              <Button className="customButton" size="lg" style={{ backgroundColor: '#243665' }} block>Proceed to Payment</Button>
           <p style={{marginTop:15}}>Get a 3 month free trial and you can cancel the subscription at any time.</p>
           
            </StripeCheckout>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {/* {subscription != '' ?
              <Button variant="primary" onClick={proceedRegistration}>
                Proceed
              </Button>
              :
              <Button disabled variant="primary">
                Proceed
              </Button>
            } */}
          </Modal.Footer>
        </Modal>
        {/* Subscription modal */}

        <Tabs activeKey={selectedTab} defaultActiveKey={selectedTab} id="uncontrolled-tab-example" onSelect={handleSelect}>
          <Tab eventKey="Login" title="Login">
            <Form className="insideForm loginForm" onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Email *</Form.Label>
                    <div className="inputWithIcon">
                      <FaEnvelopeOpenText />
                      <Form.Control type="text" name="loginemail" onChange={handleFieldValue} placeholder="Email" value={loginemail} />
                    </div>
                    {isvaildate && loginemail === '' ? <Form.Label className="validationError">Please enter email address</Form.Label> : isvaildate && emailvalidate ? <Form.Label className="validationError">Please enter valid email address</Form.Label> : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Password *</Form.Label>
                    <div className="inputWithIcon">
                      <FaLock />
                      <Form.Control type={showpwd ? 'text' : 'password'} placeholder=" Password" name="loginpassword" defaultValue={loginpassword} onChange={handleFieldValue} />
                      <i className="eyeicon" onClick={toggleShow} >{showpwd ? <FaEyeSlash /> : <FaEye />}</i>
                    </div>
                    {isvaildate && loginpassword === '' ? <Form.Label className="validationError">Please enter password</Form.Label> : null}
                  </Form.Group>
                </Col>
              </Row>
              {hidebtnload && selectedTab === 'Login' ? <Button className="customButton" variant="secondary" type="submit" block>Login to your account</Button> :
                <Button className="customButton" variant="secondary" disabled block>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </Button>}
            </Form>
          </Tab>
          <Tab eventKey="Register" title="Register">
            <Form className="insideForm" onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>First Name *</Form.Label>
                    <div className="inputWithIcon">
                      <FaUserAlt />
                      <Form.Control type="text" name="regfirstname" onChange={handleFieldValue} placeholder="First name" value={regfirstname} />
                    </div>
                    {isvaildate && regfirstname === '' ? <Form.Label className="validationError">Please enter first name</Form.Label> : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <div className="inputWithIcon">
                      <FaUserAlt />
                      <Form.Control type="text" name="reglastname" onChange={handleFieldValue} placeholder="Last name" value={reglastname} />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Email *</Form.Label>
                    <div className="inputWithIcon">
                      <FaEnvelopeOpenText />
                      <Form.Control type="text" placeholder="Email" name="regemail" value={regemail} onChange={handleFieldValue} />
                    </div>
                    {isvaildate && regemail === '' ? <Form.Label className="validationError">Please enter email</Form.Label> : isvaildate && emailvalidate ? <Form.Label className="validationError">Please enter valid email address</Form.Label> : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Phone *</Form.Label>
                    <div className="inputWithIcon">
                      <FaPhoneAlt />
                      <Form.Control type="text" placeholder=" Phone" name="regphoneno" value={regphoneno} onChange={handleFieldValue} />
                    </div>
                    {isvaildate && regphoneno === '' ? <Form.Label className="validationError">Please enter phone number</Form.Label> : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Password *</Form.Label>
                    <div className="inputWithIcon">
                      <FaLock />
                      <Form.Control type={showPpwd ? 'text' : 'password'} placeholder="Password" name="regpassword" value={regpassword} onChange={handleFieldValue} />
                      <i className="eyeicon" onClick={() => handleClick('new')} >{showPpwd ? <FaEyeSlash /> : <FaEye />}</i>
                    </div>
                    {isvaildate && regpassword === '' ? <Form.Label className="validationError">Please enter password</Form.Label> : isvaildate && pwdlength ? <Form.Label className="validationError">Please enter minimum 8 characters</Form.Label> : null}
                  </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Confirm Password *</Form.Label>
                    <div className="inputWithIcon">
                      <FaLock />
                      <Form.Control type={showCPpwd ? 'text' : 'password'} placeholder="Confirm-password" name="regconfpass" value={regconfpass} onChange={handleFieldValue} />
                      <i className="eyeicon" onClick={() => handleClick('confirm')} >
                        {showCPpwd ? <FaEyeSlash /> : <FaEye />}
                        </i>
                    </div>
                    {isvaildate && regconfpass === '' ? <Form.Label className="validationError">Please confirm password</Form.Label> : isvaildate && pwdmismatch ? <Form.Label className="validationError">Password mismatch</Form.Label> : null}
                  </Form.Group>
                </Col>
               
              </Row>
              <Row>
                <Col>
                <Form.Group>
                  <Form.Label>Number of Subscription Quantity *</Form.Label>
                  <div className='inputwithIcon'>
                    <Form.Control  name="quantity"  placeholder="Enter the subscription quantity" value={quantity} onChange={handleFieldValue}  />
                  </div>
                   {/* {isvaildate && quantity === '' ? <Form.Label className="validationError">Please enter subscription quantity</Form.Label> : null} */}
                </Form.Group>
                </Col>
              </Row>
              
              <div>
                <div className="straighline"></div>
                <></>
                <b><p style={{ marginBottom: 0, textAlign: 'center' }}>Plans</p></b>
                <></>
                <div className="straighline"></div>
                <Tabs style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', padding: '3px', borderBottom: 'none' }}
                  defaultActiveKey={false} id="uncontrolled-tab-example" onSelect={onCountrySelected} >
                  <Tab eventKey="Australia" title={<span><FaHome /> Australia</span>}>
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '28px' }}>
                        Standard Plan
                    </span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '36px' }}>$5</span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span>AUD per user/month</span>
                    </div>
                  </Tab>
                  <Tab eventKey="INDIA" title={<span><FaHome /> India</span>}>
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '28px' }}>
                        Standard Plan
                    </span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '32px', textAlign: 'left' }}>₹</span>
                      <span style={{ fontSize: '36px' }}>200</span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span>Rupee per user/month</span>
                    </div>
                  </Tab>
                  <Tab eventKey="UK" title={<span><FaHome /> UK</span>}>
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '28px' }}>
                        Standard Plan
                    </span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '36px' }}> £3</span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span>GPB per user/month</span>
                    </div>
                  </Tab>
                  <Tab eventKey="USA" title={<span><FaHome /> USA</span>}>
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '28px' }}>
                        Standard Plan
                    </span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontSize: '36px' }}>$4</span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span>USD per user/month</span>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              {subscription === "" ? <div><span style={{ color: "red",lineHeight:3 }}>*</span>Select a country to continue</div> : ''}

             <div></div>

              {subscription === "" ? <Button disabled className="customButton" variant="secondary" type="submit" block>Register</Button> :
                hidebtnload && selectedTab === 'Register' ? <Button className="customButton" variant="secondary" type="submit" block>Register</Button> :
                  // <Button className="customButton" variant="secondary" disabled block>
                  //   <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  // </Button>
                  <Button className="customButton" variant="secondary" type="submit" block>Register</Button>
              }

            </Form>

          </Tab>
          <Tab eventKey="ForgotPassword" title="Forgot Password" className="tab_left" onSubmit={handleSubmit}>
            <Form className="insideForm loginForm">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Email *</Form.Label>
                    <div className="inputWithIcon">
                      <FaEnvelopeOpenText />
                      <Form.Control type="text" name="forgotpwd" onChange={handleFieldValue} placeholder="Email" value={forgotpwd} />
                    </div>
                    {isvaildate && forgotpwd === '' ? <Form.Label className="validationError">Please enter email</Form.Label> : isvaildate && emailvalidate ? <Form.Label className="validationError">Password enter valid email</Form.Label> : null}
                  </Form.Group>
                </Col>
              </Row>
              {hidebtnload && selectedTab === 'ForgotPassword' ? <Button className="customButton" variant="secondary" type="submit" block>Forgot password</Button> :
                <Button className="customButton" variant="secondary" disabled block>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </Button>}
            </Form>
          </Tab>
        </Tabs>
      </div>
      <div className="loginBG"></div>
    </div>
  )
}

const mapStateToProps = ({ authentication: { user, login_err, reg_user_suc, reg_user_fail, reset_user_suc, reset_user_fail } }) => ({
  user, login_err, reg_user_suc, reg_user_fail, reset_user_suc, reset_user_fail
})

const mapDispatchToProps = {
  getlogin: userActions.login,
  getregister: userActions.registration,
  fgtpwd: userActions.forgotpwd
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);