import React, { useState, useEffect, useMemo } from 'react'
import Header from "../../components/header"
import Sidebar from "../../components/sidebar"
import { riskActions } from '../../actions/riskinput'
import { connect } from 'react-redux'
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/core";
import GraphComponent from './GraphComponent'
const Histography = props => {
  const [edituid, setEditUID] = useState();
  const [chartlength, setChartLength] = useState(0);
  const [spec, setSpec] = useState();
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [lowcost, setLowCost] = useState(0);
  const [highcost, setHighCost] = useState(0);
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  useEffect(() => {
    if (localStorage.getItem('uid') === null) {
      return window.location = '/';
    } else {
      var getToken = props.location.search
      var trimToken = getToken.replace("?token=", "")
      if (trimToken.length === 24) {
        setEditUID(trimToken)
        props.getExactrisk(trimToken)
      }
    }
  }, [])

  const arrayFn = (arr) => {
    return new Promise((resolve,reject)=>{
      let modifiedArr = arr.result.map(items => {
        return {
          "u": items
        }
      })
      resolve(modifiedArr)
    })
  }

  useEffect(() => {
    if (props.edit_risk_suc) {
      setChartLength(props.edit_risk_suc.data.trianinv_mcs_js.length)
      if(props.edit_risk_suc.data.trianinv_mcs_js.length > 0){
        var str = JSON.parse(props.edit_risk_suc.data.trianinv_mcs_js);
        let getArrayFormat = str.result.map(items => {
          return {
            "u": items
          }
        })
        arrayFn(str).then(data=>{
          let leftrange = 0;
          if(props.edit_risk_suc.data.lowcost < 100){
            leftrange = props.edit_risk_suc.data.lowcost - 10
            setLowCost(leftrange)
          }else if(props.edit_risk_suc.data.lowcost > 99 && props.edit_risk_suc.data.lowcost < 1000){
            leftrange = props.edit_risk_suc.data.lowcost - 20
            setLowCost(leftrange)
          }else if(props.edit_risk_suc.data.lowcost > 999 ){
            leftrange = props.edit_risk_suc.data.lowcost - 30
            setLowCost(leftrange)
          }
          let rightrange = 0;
          if(props.edit_risk_suc.data.highcost < 100){
            rightrange = props.edit_risk_suc.data.lowcost + 10
            setHighCost(rightrange)
          }else if(props.edit_risk_suc.data.highcost > 99 && props.edit_risk_suc.data.highcost < 1000){
            rightrange = props.edit_risk_suc.data.lowcost + 20
            setHighCost(rightrange)
          }else if(props.edit_risk_suc.data.highcost > 999 ){
            rightrange = props.edit_risk_suc.data.lowcost + 30
            setHighCost(props.edit_risk_suc.data.highcost - 500)
          }
          setSpec({
            "width": 800,
            "height": 500,
            "padding": 5,
          
            "signals": [
              { "name": "binOffset", "value": 0.04,
                "bind": {"input": "range", "min": -0.1, "max": 0.1} },
              { "name": "binStep", "value": 0.4,
                "bind": {"input": "range", "min": 0.001, "max": 0.4, "step": 0.001} }
            ],
          
            "data": [
              {
                "name": "points",
                "values": data
              },
              {
                "name": "binned",
                "source": "points",
                "transform": [
                  {
                    "type": "bin", "field": "u",
                    "extent": [leftrange, rightrange],
                    "anchor": {"signal": "binOffset"},
                    "step": {"signal": "binStep"},
                    "nice": false
                  },
                  {
                    "type": "aggregate",
                    "key": "bin0", "groupby": ["bin0", "bin1"],
                    "fields": ["bin0"], "ops": ["count"], "as": ["count"]
                  }
                ]
              }
            ],
          
            "scales": [
              {
                "name": "xscale",
                "type": "linear",
                "range": "width",
                "domain": [leftrange, rightrange], "zero": false, "color": "#000"
              },
              {
                "name": "yscale",
                "type": "linear",
                "range": "height", "round": true,
                "domain": {"data": "binned", "field": "count"},
                "zero": true, "nice": true, "color": "#000"
              }
            ],
          
            "axes": [
              {"orient": "bottom", "scale": "xscale", "zindex": 1, "color": "#000"},
              {"orient": "left", "scale": "yscale", "tickCount": 5, "zindex": 1, "color": "#000"}
            ],
          
            "marks": [
              {
                "type": "rect", "color": "#000",
                "from": {"data": "binned"},
                "encode": {
                  "update": {
                    "x": {"scale": "xscale", "field": "bin0"},
                    "x2": {"scale": "xscale", "field": "bin1",
                          "offset": {"signal": "binStep > 0.02 ? -0.5 : 0"}},
                    "y": {"scale": "yscale", "field": "count"},
                    "y2": {"scale": "yscale", "value": 0},
                    "fill": {"value": "steelblue"}
                  },
                  "hover": { "fill": {"value": "firebrick"} }
                }
              },
              {
                "type": "rect", "color": "#e0e0e0",
                "from": {"data": "points"},
                "encode": {
                  "enter": {
                    "x": {"scale": "xscale", "field": "u"},
                    "width": {"value": 1},
                    "y": {"value": 25, "offset": {"signal": "height"}},
                    "height": {"value": 5},
                    "fill": {"value": "steelblue"},
                    "fillOpacity": {"value": 0.4}
                  }
                }
              }
            ]
          })
          setLoading(false)
        })
      }
    }
  }, [props.edit_risk_suc])
    
    return (
      <div className="black">
        <Header />
        <div className="pageContain">
          <Sidebar />
          <div className="contentWrapper">
            <MoonLoader css={override} size={60} color={"#123abc"} loading={loading} />
            {loading === false && <GraphComponent loading={loading} spec={spec} />}
          </div>
        </div>
      </div>
    )
}

const mapStateToProps = ({ riskinputs: { edit_risk_suc, edit_risk_fail } }) => ({
  edit_risk_suc, edit_risk_fail
})

const mapDispatchToProps = {
  getExactrisk: riskActions.editrisk
}

export default connect(mapStateToProps, mapDispatchToProps)(Histography);