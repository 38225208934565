import { userdetailsConstants } from "./constants";
const axios = require("axios");
// const baseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = 'https://foresightapi.infrahub.co/'
 const baseUrl1 = "http://localhost:8081/"
// http://localhost:8081/
// https://foresightapi.infrahub.co/


function getUsers(searchvalue, activePage) {
  return dispatch => {
    dispatch(request({}));
    axios.get(baseUrl+'user/?userid='+ localStorage.getItem('uid')+'&page='+activePage+'&perpage=10&search='+searchvalue+'&sortBy=createdon&orderBy=DESC').then(function(response) {
      console.log("test response in actions...",response.data)
     
      if (response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error);
      dispatch(failure(error));
    });
  };
  
  function request(getalluser) {
    return { type: userdetailsConstants.GETALLUSERS_REQUEST, getalluser };
  }
  function success(getallusersuc ) {
    return { type: userdetailsConstants.GETALLUSERS_SUCCESS, getallusersuc };
  }
  function failure(getallusererror) {
    return { type: userdetailsConstants.GETALLUSERS_FAILURE, getallusererror };
  }
}

function addusers(data) {
  return dispatch => {
    dispatch(request({ data }));
    console.log(data,"pppp")
    axios
    // / baseUrl1+'user/getallusers/?userid='+ localStorage.getItem('uid')
      .post(baseUrl + 'user/adduser/?userid='+ localStorage.getItem('uid'),data
      )
      .then(function(response) { 

        if (response.data.status !== undefined && response.data.status === 1) {
  
          dispatch(success(response.data));
        } else {
          let message = response.data.message
            ? response.data.message
            : "Incorrect credentials";
          dispatch(failure(message));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: userdetailsConstants.ADDUSER_REQUEST, user };
  }
  function success(useradd) {
    return { type: userdetailsConstants.ADDUSER_SUCCESS, useradd };
  }
  function failure(usererror) {
    return { type: userdetailsConstants.ADDUSER_FAILURE, usererror };
  }
}

function updateuser(data, userid) {
  return dispatch => {
    dispatch(request({ data }));
    axios
      .patch(baseUrl + "user/updateuser/"+userid, 
        data
      )
      .then(function(response) { 
        if (response.data.status !== undefined && response.data.status === 1) {
          // localStorage.setItem('fname', response.data.data.firstname);
          // localStorage.setItem('lname', response.data.data.lastname);
          // localStorage.setItem('phoneno', response.data.data.phoneno);
          localStorage.setItem('role', response.data.data.role);
          dispatch(success(response.data));
        } else {
          let message = response.data.message
            ? response.data.message
            : "Incorrect credentials";
          dispatch(failure(message));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request(userreq) {
    return { type: userdetailsConstants.UPDATEUSER_REQUEST, userreq };
  }
  function success(useredit) {
    return { type: userdetailsConstants.UPDATEUSER_SUCCESS, useredit };
  }
  function failure(userediterror) {
    return { type: userdetailsConstants.UPDATEUSER_FAILURE, userediterror };
  }
}

function getallUsers() {
  return dispatch => {
      dispatch(request({}));
      axios.get(baseUrl+'user/getallusers/?userid='+ localStorage.getItem('uid')).then(function(response) {
        if (response.data.status !== undefined && response.data.status === 1) {
          dispatch(success(response.data));
        } else {
          let message = response.data.message
            ? response.data.message
            : "Incorrect credentials";
          dispatch(failure(message));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(failure(error));
      });
  };
  
  function request(getalluser) {
      return { type: userdetailsConstants.GETALLUSERS_REQUEST, getalluser };
  }
  function success(getallusersuc ) {
      return { type: userdetailsConstants.GETALLUSERS_SUCCESS, getallusersuc };
  }
  function failure(getallusererror) {
      return { type: userdetailsConstants.GETALLUSERS_FAILURE, getallusererror };
  }
}

export const usersActions = {
    getUsers, addusers, updateuser, getallUsers
};
