import React, { useState } from 'react';
import { Button, Modal, Form, Row, Col, Alert } from "react-bootstrap";

const ImportModel = (props) => {
    const [selectedFile, File] = useState(null);
    const [enableImportSubmit, setimportEnable] = useState(false);
    const [showtoast, setShowToast] = useState(false);
    const [toastmsg, setToastMsg] = useState('');
    const onChangeHandler = (e) => {
        setimportEnable(true)
        File(e.target.files[0])
    }
    const downloadBtn = {
        "marginRight": "140px",
        "marginLeft": "0"
    }
    const downloadFile = () => { debugger
        window.location.href = "/ImportTemplate.xlsx"
    }
    return (
        <>
            {showtoast ?
                <Alert variant='danger'>
                    This is a  alert—check it out!
                </Alert>
            : ''}

            <Modal.Dialog className={`toast_msgs success`}>
                <Modal.Header>
                    <Modal.Title>Import Risks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label style={{paddingRight:'360px'}}>Upload file</Form.Label>
                                    <div className="inputWithIcon" style={{paddingRight:'150px'}}>
                                        <input type="file" name="file" onChange={onChangeHandler} />
                                    </div>
                                    {/* {this.state.checkvalidation && this.state.selectedFile === null ? <Form.Label id="firstnameerror" className="validationError validation_msg">Please select a file</Form.Label>: null }
                                    {this.state.showrespmsg && this.state.responsemsg != '' ? <Form.Label id="firstnameerror" className="validationError validation_msg alignment_res">{this.state.responsemsg}</Form.Label>: null } */}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="padding0">
                    <Button variant="primary" style={downloadBtn} onClick={downloadFile} >Download Template</Button>
                    <Button variant="link" onClick={() => props.handleShow(false)}>Close</Button>
                    {enableImportSubmit ? <Button variant="primary" onClick={(condition) => props.importSubmit(selectedFile)}>Submit</Button> : <Button variant="primary" disabled>Submit</Button>}
                </Modal.Footer>
            </Modal.Dialog>
            
        </>
    )
}
export default ImportModel;