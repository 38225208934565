export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    REGISTRATION_REQUEST: 'USERS_REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS: 'USERS_REGISTRATION_SUCCESS',
    REGISTRATION_FAILURE: 'USERS_REGISTRATION_FAILURE',
    RESET_REQUEST: 'USERS_RESET_REQUEST',
    RESET_SUCCESS: 'USERS_RESET_SUCCESS',
    RESET_FAILURE: 'USERS_RESET_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
};

export const LOGOUT = 'LOGOUT'

export const STATUS_CHANGED_SUCCESSFULLY = 'STATUS_CHANGED_SUCCESSFULLY';

export const resetConstants = {
    RESET_REQUEST: 'USERS_RESET_REQUEST',
    RESET_SUCCESS: 'USERS_RESET_SUCCESS',
    RESET_FAILURE: 'USERS_RESET_FAILURE'
};

export const riskConstants = {
    ADDRISK_REQUEST: 'USERS_ADDRISK_REQUEST',
    ADDRISK_SUCCESS: 'USERS_ADDRISK_SUCCESS',
    ADDRISK_FAILURE: 'USERS_ADDRISK_FAILURE',
    GETTABLERISK_REQUEST: 'USERS_GETTABLERISK_REQUEST',
    GETTABLERISK_SUCCESS: 'USERS_GETTABLERISK_SUCCESS',
    GETTABLERISK_FAILURE: 'USERS_GETTABLERISK_FAILURE',

    GETTABLERISKUERS_REQUEST: 'USERS_GETTABLERISKUERS_REQUEST',
    GETTABLERISKUSER_SUCCESS: 'USERS_GETTABLERISKUSER_SUCCESS',
    GETTABLERISKUSER_FAILURE: 'USERS_GETTABLERISKUSER_FAILURE',

    EDITRISK_REQUEST: 'USERS_EDITRISK_REQUEST',
    EDITRISK_SUCCESS: 'USERS_EDITRISK_SUCCESS',
    EDITRISK_FAILURE: 'USERS_EDITRISK_FAILURE',
    UPDATERISK_REQUEST: 'USERS_UPDATERISK_REQUEST',
    UPDATERISK_SUCCESS: 'USERS_UPDATERISK_SUCCESS',
    UPDATERISK_FAILURE: 'USERS_UPDATERISK_FAILURE',
    GETALLPROJECT_REQUEST: 'USERS_GETALLPROJECT_REQUEST',
    GETALLPROJECT_SUCCESS: 'USERS_GETALLPROJECT_SUCCESS',
    GETALLPROJECT_FAILURE: 'USERS_GETALLPROJECT_FAILURE',
    GETRISKSETTINGS_REQUEST: 'USERS_GETRISKSETTINGS_REQUEST',
    GETRISKSETTINGS_SUCCESS: 'USERS_GETRISKSETTINGS_SUCCESS',
    GETRISKSETTINGS_FAILURE: 'USERS_GETRISKSETTINGS_FAILURE'
}

export const dashboardConstants = {
    GETSINGLEPROJECT_REQUEST: 'USERS_GETSINGLEPROJECT_REQUEST',
    GETSINGLEPROJECT_SUCCESS: 'USERS_GETSINGLEPROJECT_SUCCESS',
    GETSINGLEPROJECT_FAILURE: 'USERS_GETSINGLEPROJECT_FAILURE',
    GETSINGLETORNADO_REQUEST: 'USERS_GETSINGLETORNADO_REQUEST',
    GETSINGLETORNADO_SUCCESS: 'USERS_GETSINGLETORNADO_SUCCESS',
    GETSINGLETORNADO_FAILURE: 'USERS_GETSINGLETORNADO_FAILURE'
}

export const userdetailsConstants = {
    ADDUSER_REQUEST: 'ADDUSER_REQUEST',
    ADDUSER_SUCCESS: 'ADDUSER_SUCCESS',
    ADDUSER_FAILURE: 'ADDUSER_FAILURE',
    GETALLUSERS_REQUEST: 'USERS_GETALLUSERS_REQUEST',
    GETALLUSERS_SUCCESS: 'USERS_GETALLUSERS_SUCCESS',
    GETALLUSERS_FAILURE: 'USERS_GETALLUSERS_FAILURE',
    UPDATEUSER_REQUEST: 'USERS_UPDATEUSER_REQUEST',
    UPDATEUSER_SUCCESS: 'USERS_UPDATEUSER_SUCCESS',
    UPDATEUSER_FAILURE: 'USERS_UPDATEUSER_FAILURE'
}


export const profileConstants = {
    GET_PROFILE_REQUEST : 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
}

export const probabilityOptions = [
    { value: '0.01', label: '1%' },
    { value: '0.02', label: '2%' },
    { value: '0.03', label: '3%' },
    { value: '0.04', label: '4%' }
  ];