import { resetConstants } from "./constants";
const axios = require("axios");
// const baseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = 'https://foresightapi.infrahub.co/'
 const baseUrl1 = 'http://localhost:8081/'

function resetpawd(password, token) {
  return dispatch => {
    dispatch(request({ password }));
    axios
      .post(baseUrl + "user/resetpassword", {
        password: password,
        token : token
      })
      .then(function(response) {
        console.log(response,"in reset password")
        if (response.data.status !== undefined && response.data.status === 1) {
          dispatch(success(response.data));
        } else {
          let message = response.data.message
            ? response.data.message
            : "Incorrect credentials";
          dispatch(failure(message));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: resetConstants.RESET_REQUEST, user };
  }
  function success(resetuser) {
    return { type: resetConstants.RESET_SUCCESS, resetuser };
  }
  function failure(reseterror) {
    return { type: resetConstants.RESET_FAILURE, reseterror };
  }
}

export const resetActions = {
    resetpawd
};
