import {
    userdetailsConstants
} from "../actions/constants";

let user = localStorage.getItem("uid");
const initialState = user ? {
    loading: true,
    error : false,
    message: undefined,
    getallusers : '',
    getallusers_suc : '',
    getallusers_fail : '',
    add_user_suc : '',
    add_user_fail : '',
    edit_user_suc : '',
    edit_user_fail : ''
} : {};

function users(state = initialState, action) { 
    switch (action.type) {
        case userdetailsConstants.ADDUSER_REQUEST:
            return {
                ...state,
                loading: false,
                add_user_suc : '',
                add_user_fail : ''
            };
        case userdetailsConstants.ADDUSER_SUCCESS:
            return {
                ...state,
                loading: false,
                add_user_suc: action.useradd
            };
        case userdetailsConstants.ADDUSER_FAILURE:
            return {
                ...state,
                loading: false,
                add_user_fail : action.usererror
            };
        case userdetailsConstants.UPDATEUSER_REQUEST:
            return {
                ...state,
                loading: false,
                edit_user_suc : '',
                edit_user_fail : ''
            };
        case userdetailsConstants.UPDATEUSER_SUCCESS:
            return {
                ...state,
                loading: false,
                edit_user_suc: action.useredit
            };
        case userdetailsConstants.UPDATEUSER_FAILURE:
            return {
                ...state,
                loading: false,
                edit_user_fail : action.userediterror
            };
        case userdetailsConstants.GETALLUSERS_REQUEST:
            console.log(action,"111111111114444444444");
            return {
                ...state,
                loading: false,
                getallusers_suc : '',
                getallusers_fail : ''
            };
        case userdetailsConstants.GETALLUSERS_SUCCESS:
            console.log(action,"reducesssssrrr");
            return {
                ...state,
                loading: false,
                getallusers_suc: action.getallusersuc
            };
        case userdetailsConstants.GETALLUSERS_FAILURE:
            console.log(action,"faaiiilllll");
            return {
                ...state,
                loading: false,
                getallusers_fail : action.getallusererror
            };
        default:
            return state;
    }
}

export default users;