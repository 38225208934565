import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const  LogoutModal = (props)=> {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    window.location = '/login';
  };

  const { logout} = props;


  return (
    <div>
      <Dialog
        open={open}
        onClose={()=>setOpen(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Subscription has expired
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Kindly contact  <a href="https://www.infrahub.co/foresight" target="_blank">Foresight </a>for more information.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Logout</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LogoutModal;



  // const handleClose = () => {
  //   setOpen(false);
  // };

//   return (
//     <div>
    
//       <Dialog
//         open={open}
//         onClose={open}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Use Google's location service?"}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Let Google help apps determine location. This means sending anonymous
//             location data to Google, even when no apps are running.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Logout</Button>
       
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }
