import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Authentication from '../pages/Authentication'
import Dashboard from '../pages/Dashboard'
import Resetpassword from '../pages/ResetPassword'
import Risks from '../pages/Risks'
import Allriskhubs from '../pages/Allriskhubs'
import Myprofile from '../pages/Myprofile'
import Users from '../pages/Users'
import Histography from '../pages/Histography'
import RiskHub from '../pages/RiskHub'
import SubscriptionSettings from '../pages/subscriptionsetting'
import SubscriptionEndPage from '../pages/SubscriptionEndPage'

const Routes = () =>  {
    return (
        <Router>    
            <Switch>
            <Route path="/resetpassword" component={Resetpassword} />
                <Route path="/risks" component={Risks} />
                <Route path="/allriskhubs" component={Allriskhubs} />
                <Route path="/myprofile" component={Myprofile} />
                <Route path="/users" component={Users} />
                <Route path="/histography" component={Histography} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path ="/riskhub" component={RiskHub} />
                <Route path ="/subscriptions" component={SubscriptionSettings} />
                <Route path ="/subscriptionhasended" component={SubscriptionEndPage} />
                <Route path="/" component={Authentication} />
            </Switch>
        </Router>
    )
}

export default Routes;