import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Button, Table, Card, Row, Col, Container } from 'react-bootstrap'
import { FaWpforms } from "react-icons/fa";
import Header from '../../components/header'
import Sidebar from '../../components/sidebar'
import { riskActions } from '../../actions/riskinput'
import { useHistory } from "react-router-dom"
const axios = require('axios');
const baseUrl = 'https://foresightapi.infrahub.co/';
 const baseUrl1 = 'http://localhost:8081/'
// http://localhost:8081/

const SubscriptionEndPage = (props) => {

    useEffect(() => {
        if (localStorage.getItem('uid') === null) {
            return window.location = '/';
        } else {
            // getTabelData()
        }
    }, [])

   const logoutClick = (e) => { debugger
        var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN')
        var data = {
            fcm: sessionStorage.getItem('fcm'),
            email: sessionStorage.getItem('email')
        }
        axios.get(baseUrl + 'index/logout', data, { headers: { authorization: AuthStr } })
            .then(res => {
                sessionStorage.clear();
                localStorage.clear();
                return window.location = '/login';
            }).catch(function (error) {
                console.log(error)
            });
    }


    return (
        <div>
            <div className="black">
                <div className="pageContain">
                    <div>
                        <Container style={{padding: '52px'}}>
                            <Card style={{ width: '100%' }}>
                                <Card.Body>
                                    {/* <Card.Title>Card Title</Card.Title> */}
                                        <div style={{textAlign: 'center'}}>
                                            <img src="foresight.jpg" ></img>
                                        </div>
                                        <div style={{textAlign: 'center'}}>
                                            <h1><span style={{fontSize: 'xx-large'}}> Subscription has expired</span></h1>
                                                <h3>Kindly contact  <a href="https://www.infrahub.co/foresight" target="_blank">Foresight </a>for more information</h3>
                                        </div>
                                    <Card.Text >
                                    </Card.Text>
                                    <div style={{textAlign:'center'}}>
                                        <Button variant="primary" onClick={logoutClick}>LogOut</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

// export default RiskHub;
const mapStateToProps = ({ riskinputs: { getrisksettings_suc } }) => ({
    getrisksettings_suc
})

const mapDispatchToProps = {
    getRiskSettingList: riskActions.getRiskSettingList
    // updateprofile: riskActions.updateprofile
}


export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionEndPage);