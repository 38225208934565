import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Button, Table, Card, Row, Col } from 'react-bootstrap'
import { FaWpforms } from "react-icons/fa";
import Header from '../../components/header'
import Sidebar from '../../components/sidebar'
import { riskActions } from '../../actions/riskinput'
import { useHistory } from "react-router-dom"
const axios = require('axios');
const baseUrl = 'https://foresightapi.infrahub.co/';
 const baseUrl1 = 'http://localhost:8081/'
// http://localhost:8081/


const RiskHub = (props) => {
    const history = useHistory()
    const [arrdata, alldata] = useState([])

    useMemo(() => {
        if (props.getrisksettings_suc && props.getrisksettings_suc.data != null) {
            var objarray = [];
            objarray = Object.entries(props.getrisksettings_suc.data);
            objarray.length = 21
            alldata(objarray)
        }
        //  else if(props.getrisksettings_suc.status === 1 && props.getrisksettings_suc.data === null){

        // }
    }, [props.getrisksettings_suc])

    const getTabelData = () => {
        props.getRiskSettingList(localStorage.getItem('uid'))
    }
    useEffect(() => {
        if (localStorage.getItem('uid') === null) {
            return window.location = '/';
        } else {
            getTabelData()
        }
    }, [])

    const onChangeValue = (e) =>{
        alert('')
    }
    

    return (
        <div>
            <Header />
            <div className="black">
                <div className="pageContain">
                    <Sidebar />
                    <div className="content phoneBookContain">
                        <div className="pageTitleWrapper">
                            <div className="alert_custom_part">
                                <div className="pageTitleHeading deviceHeading">
                                    <span className="pageTitleHeadingIcon"><FaWpforms /></span>
                                    Subscription Settings
                                </div>
                                {arrdata.length > 0 ? '' : <div style={{ textAlign: 'end' }}>
                                    <span className="pageTitleHeadingIcon">
                                        {/* <Button onClick={createsetings} variant="primary">Create Settings</Button> */}
                                    </span>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="content phoneBookContain">
                        <div className="pageTitleWrapper">
                        <Card style={{ width: '100%', height: '30em'}}>
                        <div  className="radio-toolbar" onChange={(e) => onChangeValue(e)} style={{paddingTop: '5%'}}>
                            <Row >
                                <Col xs={12} md={6} style={{ textAlign: "center" }}>
                                    <input type="radio" id="subscription1" name="sub" value="subscription" />
                                    <label htmlFor="subscription1" style={{color:'white'}}>Subscription 1</label>
                                </Col>

                                <Col xs={12} md={6} style={{ textAlign: "center" }}>
                                    <input type="radio" id="cancelsubscription" name="sub" value="cancelsubscription" />
                                    <label htmlFor="cancelsubscription" style={{color:'white'}}>Subscription 2</label>
                                </Col>
                            </Row>
                        </div>
                        {/* <div>
                            <Row>
                                <Button>proceed</Button>
                            </Row>
                        </div> */}
                        </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// export default RiskHub;
const mapStateToProps = ({ riskinputs: { getrisksettings_suc } }) => ({
    getrisksettings_suc
})

const mapDispatchToProps = {
    getRiskSettingList: riskActions.getRiskSettingList
    // updateprofile: riskActions.updateprofile
}


export default connect(mapStateToProps, mapDispatchToProps)(RiskHub);