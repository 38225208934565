import { profileConstants } from "../actions/constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {
    loading: true,
    error : false,
    message: undefined,
    login_err : '',
    profile_req : '',
    profile_suc : '',
    profile_fail : ''
} : {};

function myprofile(state = initialState, action) { 
    switch (action.type) {
        case profileConstants.GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile_suc: action.myprofileksuc
            };
        case profileConstants.GET_PROFILE_REQUEST:
            return {
                ...state,
                loading: false,
                profile_req: action.getprofile
            };
        case profileConstants.GET_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                profile_fail : action.myprofileerror
            };
        default:
            return state;
    }
}

export default myprofile;