import {
    userConstants
} from "../actions/constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {
    loading: true,
    error : false,
    message: undefined,
    user
} : {};

function authentication(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

export default authentication;