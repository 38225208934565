import React,{useState, useMemo} from 'react'
import { Form, Row, Col, Button, Spinner, Toast } from 'react-bootstrap'
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa'
import { connect } from 'react-redux'
import { resetActions } from '../../actions/resetpwd'
import { useHistory } from "react-router-dom";

const Resetpassword = props => {
  const history = useHistory()
  const [showPpwd, setshowPpwd] = useState('')
  const [resetpwd, setResetPwd] = useState('')
  const [showCPpwd, setshowCPpwd] = useState('')
  const [resconfpass, setresconfpass] = useState('')
  const [isvaildate, setisvaildate] = useState(false)
  const [pwdlength, setpwdlength] = useState(false)
  const [pwdmismatch, setPwdMismatch] = useState(false);
  const [hidebtnload, setHideBtnLoad] = useState(true);
  const [token, setToken] = useState('');
  const [showtoast, setShowToast] = useState(false);
  const [toastmsg, setToastMsg] = useState('');
  const [msgclass, setMsgClass] = useState('');

  useMemo(() => {
    if(props.location.search){
      var getToken = props.location.search
      var trimToken = getToken.replace("?token=", "")
      setToken(trimToken)
    }
  },[props.location.search])

  const removeToast = () => {
    setTimeout(() => {
      setShowToast(false)
      setToastMsg('')
      if(msgclass === 'success'){
        history.push('/')
      }
    }, 5000)
  }

  const handleFieldValue = (e) => {
    switch(e.target.name){
      case 'resetpwd':
        return setResetPwd(e.target.value)
      case 'resconfpass':
        return setresconfpass(e.target.value)
      default:
        return setHideBtnLoad(true)
    }
  }

  const handleClick = (e) => {
    if(e === 'new'){
      setshowPpwd(!showPpwd)
    }else if(e === 'confirm'){
      setshowCPpwd(!showCPpwd)
    }
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    setisvaildate(true)
    if(resetpwd && resconfpass){
      if(resetpwd.length > 7 ){
        setpwdlength(false)
        if(resetpwd === resconfpass){
          setPwdMismatch(false)
          if(token){
            setHideBtnLoad(false)
            props.resetpwd(resetpwd, token)
          }
        }else{
          setPwdMismatch(true)
        }
      }else{
        setpwdlength(true)
      }
    }
  }
  
  useMemo(() => {
    if(props.reset_fail){
      setShowToast(true)
      setToastMsg(props.reset_fail)
      setHideBtnLoad(true)
      setMsgClass('error')
      setisvaildate(false)
      removeToast()
    }
  },[props.reset_fail])

  useMemo(() => {
    if(props.reset_suc){
      setShowToast(true)
      setToastMsg(props.reset_suc.message)
      setHideBtnLoad(true)
      setMsgClass('success')
      setisvaildate(false)
      removeToast()
      setResetPwd('')
      setresconfpass('')
    }
  },[props.reset_suc])

  return(
    <div className="reset_bg" style={{ backgroundImage: 'url(MainBg.jpg)' }}>
      <Toast animation={false} show={showtoast} className={`toast_msgs ${msgclass}` }>
        <Toast.Body>
        <strong className="mr-auto">{toastmsg}</strong>
        </Toast.Body>
      </Toast>
      <div className="reset_pwd">
        <div className="reset_heading">Reset Password</div>
        <Form className="insideForm" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Password *</Form.Label>
                <div className="inputWithIcon">
                  <FaLock />
                  <Form.Control type={showPpwd ? 'text' : 'password'} placeholder="Enter passowrd" name="resetpwd" value={resetpwd}  onChange={handleFieldValue}/>
                  <i className="eyeicon" onClick={() => handleClick('new')} >{showPpwd ? <FaEyeSlash /> : <FaEye />}</i>
                </div>
                {isvaildate && resetpwd === '' ? <Form.Label className="validationError">Please enter password</Form.Label> : isvaildate && pwdlength ? <Form.Label className="validationError">Please enter minimum 8 characters</Form.Label> : null }
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Confirm Password *</Form.Label>
                <div className="inputWithIcon">
                  <FaLock />
                  <Form.Control type={showCPpwd ? 'text' : 'password'} placeholder="Enter confirm password" name="resconfpass" value={resconfpass}  onChange={handleFieldValue}/>
                  <i className="eyeicon" onClick={() => handleClick('confirm')} >{showCPpwd ? <FaEyeSlash /> : <FaEye />}</i>
                </div>
                {isvaildate && resconfpass === '' ? <Form.Label className="validationError">Please confirm password</Form.Label> : isvaildate && pwdmismatch ? <Form.Label className="validationError">Password mismatch</Form.Label> : null }
              </Form.Group>
            </Col>
          </Row>
          {hidebtnload ? <Button className="customButton" variant="secondary" type="submit" block>Reset Password</Button> :
          <Button className="customButton" variant="secondary" disabled block>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          </Button> }
        </Form>
      </div>
    </div>
  )
}

const mapStateToProps = ({resetpwd:{reset_suc, reset_fail}}) => ({
  reset_suc, reset_fail
})

const mapDispatchToProps = {
  resetpwd : resetActions.resetpawd
}

export default connect (mapStateToProps, mapDispatchToProps)(Resetpassword);