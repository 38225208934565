import {
    resetConstants
} from "../actions/constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {
    loading: true,
    error : false,
    message: undefined,
    login_err : '',
    reset_suc : '',
    reset_fail : '',
} : {};

function resetpwd(state = initialState, action) {
    switch (action.type) {
        case resetConstants.RESET_REQUEST:
            return {
                ...state,
                loading: false,
                reset_suc : '',
                reset_fail : ''
            };
        case resetConstants.RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                reset_suc: action.resetuser
            };
        case resetConstants.RESET_FAILURE:
            return {
                ...state,
                loading: false,
                reset_fail : action.reseterror
            };
        default:
            return state;
    }
}

export default resetpwd;