import { profileConstants } from "./constants";
const axios = require("axios");
// const baseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = 'https://foresightapi.infrahub.co/'
 const baseUrl1 = 'http://localhost:8081/'

function getmyprofileData() { 
  return dispatch => {
    dispatch(request({}));
    axios.get(baseUrl+'user/5fc2276334dafe59931a99b9').then(function(response) { 
      if (response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error);
      dispatch(failure(error));
    });
  };
  function request(getprofile) {
    return { type: profileConstants.GET_PROFILE_REQUEST, getprofile };
  }
  function success(myprofileksuc ) {
    return { type: profileConstants.GET_PROFILE_SUCCESS, myprofileksuc };
  }
  function failure(myprofileerror) {
    return { type: profileConstants.GET_PROFILE_FAILURE, myprofileerror };
  }
}
function updateprofile(data, userid) { 
  return dispatch => {
    dispatch(request({ data }));
    axios
      .patch(baseUrl + "user/updateuser/"+userid, 
        data
      )
      .then(function(response) { 
        if (response.data.status !== undefined && response.data.status === 1) {
          localStorage.setItem('fname', response.data.data.firstname);
          localStorage.setItem('lname', response.data.data.lastname);
          localStorage.setItem('phoneno', response.data.data.phoneno);
          localStorage.setItem('role', response.data.data.role);
          dispatch(success(response.data));
        } else {
          let message = response.data.message
            ? response.data.message
            : "Incorrect credentials";
          dispatch(failure(message));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request(getprofile) {
    return { type: profileConstants.GET_PROFILE_REQUEST, getprofile };
  }
  function success(myprofileksuc ) {
    return { type: profileConstants.GET_PROFILE_SUCCESS, myprofileksuc };
  }
  function failure(myprofileerror) {
    return { type: profileConstants.GET_PROFILE_FAILURE, myprofileerror };
  }
}

export const myprofileActions = {
  getmyprofileData, updateprofile
}