import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Button, Table } from 'react-bootstrap'
import { FaWpforms } from "react-icons/fa";
import Header from '../../components/header'
import Sidebar from '../../components/sidebar'
import { riskActions } from '../../actions/riskinput'
import { useHistory } from "react-router-dom"
const axios = require('axios');
const baseUrl = 'https://foresightapi.infrahub.co/';
 const baseUrl1 = 'http://localhost:8081/'
// http://localhost:8081/


const RiskHub = (props) => {
    const history = useHistory()
    const [arrdata, alldata] = useState([])

    useMemo(() => {
        if (props.getrisksettings_suc && props.getrisksettings_suc.data != null) {
            var objarray = [];
            objarray = Object.entries(props.getrisksettings_suc.data);
            objarray.length = 35
            alldata(objarray)
        } else if (props.getrisksettings_suc && props.getrisksettings_suc.data === null) {
            var body = {
                user: localStorage.getItem('uid')
            }
            axios.post(baseUrl + 'risks/postrisksettings', body)
                .then(response => {
                    history.push('/allriskhubs')
                }).catch(function (error) {
                    console.log(error);
                })
        }
        //  else if(props.getrisksettings_suc.status === 1 && props.getrisksettings_suc.data === null){

        // }
    }, [props.getrisksettings_suc])

    const getTabelData = () => {
        props.getRiskSettingList(localStorage.getItem('uid'))

    }
    useEffect(() => {
        if (localStorage.getItem('uid') === null) {
            return window.location = '/';
        } else {
            getTabelData()
           
        }
    }, [])


    const handleInputChange = (name) => {
        alldata('');
        var checkedData = []
        arrdata.filter((obj) => {
            if (obj[0] === name) {
                obj[1] === true ? obj[1] = false : obj[1] = true;
            }
            checkedData.push(obj)
            alldata(checkedData)
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()

        var body = {
            user: localStorage.getItem('uid'),
            projectname: e.currentTarget[0].checked,
            projectno: e.currentTarget[1].checked,
            riskid: e.currentTarget[2].checked,
            riskorhazard: e.currentTarget[3].checked,
            cause: e.currentTarget[4].checked,
            control1: e.currentTarget[5].checked,
            control2: e.currentTarget[6].checked,
            control3: e.currentTarget[7].checked,
            estimate: e.currentTarget[8].checked,
            status: e.currentTarget[9].checked,
            category: e.currentTarget[10].checked,
            riskreporter: e.currentTarget[11].checked,
            riskowner: e.currentTarget[12].checked,
            date: e.currentTarget[13].checked,
            realisationdate: e.currentTarget[14].checked,
            probability: e.currentTarget[15].checked,
            lowcost: e.currentTarget[16].checked,
            likelycost: e.currentTarget[17].checked,
            highcost: e.currentTarget[18].checked,
            deterministicrisk: e.currentTarget[19].checked,
            iterations: e.currentTarget[20].checked,
            risksource: e.currentTarget[21].checked,
            riskcatagory: e.currentTarget[22].checked,
            impact: e.currentTarget[23].checked,
            risklevel: e.currentTarget[24].checked,
            riskexposure: e.currentTarget[25].checked,
            mitigationstatus: e.currentTarget[26].checked,
            contingencystatus: e.currentTarget[27].checked,
            actionimplemented: e.currentTarget[28].checked,
            remarks: e.currentTarget[29].checked,
            mitigationplannedclosure: e.currentTarget[30].checked,
            mitigationactualclosure: e.currentTarget[31].checked,
            contingencyplannedclosuredate: e.currentTarget[32].checked,
            contingencyactualclosuredate: e.currentTarget[33].checked,
            closuredate: e.currentTarget[34].checked,
        }
   
        axios.post(baseUrl + 'risks/postrisksettings', body).then(response => {
       
            history.push('/allriskhubs')
        }).catch(function (error) {
     
            console.log(error);
        })
    }

    return (
        <div>
            <Header />
            <div className="black">
                <div className="pageContain">
                    <Sidebar />
                    <div className="content phoneBookContain">
                        <div className="pageTitleWrapper">
                            <div className="alert_custom_part">
                                <div className="pageTitleHeading deviceHeading">
                                    <span className="pageTitleHeadingIcon"><FaWpforms /></span>
                                    RiskHub Settings
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{ 'textAlign': 'center' }}>#</th>
                                            <th>Column Name</th>
                                            <th style={{ 'textAlign': 'center' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arrdata
                                            ? arrdata.length > 0 ? arrdata.map((details, i) => (

                                                <tr key={i}>
                                                    <td style={{ 'textAlign': 'center' }}>{++i}</td>
                                                    <td>{
                                                        details[0] === 'projectname' ? 'Project Name' :
                                                            details[0] === 'projectno' ? 'Project Number' :
                                                                details[0] === 'riskid' ? 'Risk Id' :
                                                                    details[0] === 'riskorhazard' ? 'Risk Description' :
                                                                        details[0] === 'cause' ? 'Cause' :
                                                                            details[0] === 'control1' ? 'Mitigation Plan' :
                                                                                details[0] === 'control2' ? 'Contingency Plan' :
                                                                                    details[0] === 'control3' ? 'Control 3' :
                                                                                        details[0] === 'estimate' ? 'Basis of Estimate' :
                                                                                            details[0] === 'status' ? 'Status' :
                                                                                                details[0] === 'category' ? 'Category' :
                                                                                                    details[0] === 'riskreporter' ? 'Identified By' :
                                                                                                        details[0] === 'riskowner' ? 'Person Responsible' :
                                                                                                            details[0] === 'date' ? 'Identified Date' :
                                                                                                                details[0] === 'realisationdate' ? 'Due/Expected Closure Date' :
                                                                                                                    details[0] === 'probability' ? 'Probability' :
                                                                                                                        details[0] === 'lowcost' ? 'Low Cost' :
                                                                                                                            details[0] === 'likelycost' ? 'Likely Cost' :
                                                                                                                                details[0] === 'highcost' ? 'High Cost' :
                                                                                                                                    details[0] === 'deterministicrisk' ? 'Deterministic Risk' :
                                                                                                                                        details[0] === 'iterations' ? '# of Iterations' :
                                                                                                                                            details[0] === 'risksource' ? 'Risk Source' :
                                                                                                                                                details[0] === 'riskcatagory' ? 'Risk Category' :
                                                                                                                                                    details[0] === 'impact' ? 'Impact' :
                                                                                                                                                        details[0] === 'risklevel' ? 'Risk Level' :
                                                                                                                                                            details[0] === 'riskexposure' ? 'Risk Exposure' :
                                                                                                                                                                details[0] === 'mitigationstatus' ? 'Mitigation Status' :
                                                                                                                                                                    details[0] === 'contingencystatus' ? 'Contingency Status' :
                                                                                                                                                                        details[0] === 'actionimplemented' ? 'Action Implemented' :
                                                                                                                                                                            details[0] === 'remarks' ? 'Remarks' :
                                                                                                                                                                                details[0] === 'mitigationplannedclosure' ? 'Mitigation Planned Closure Date' :
                                                                                                                                                                                    details[0] === 'mitigationactualclosure' ? 'Mitigation Actual Closure Date' :
                                                                                                                                                                                        details[0] === 'contingencyplannedclosuredate' ? 'Contingency Planned Closure Date' :
                                                                                                                                                                                            details[0] === 'contingencyactualclosuredate' ? 'Contingency Actual Closure Date' :
                                                                                                                                                                                                details[0] === 'closuredate' ? 'Closure Date' :
                                                                                                                                                                                                    ''
                                                    }</td>
                                                    <td style={{ 'textAlign': 'center' }}>
                                                        <label className="switch">
                                                            <input
                                                                name="isGoing"
                                                                type="checkbox"
                                                                checked={details[1]}
                                                                onChange={() => handleInputChange(details[0])} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            ))
                                                :
                                                <tr><td colSpan="21" className="empty_records">No records found</td></tr>
                                            : <tr><td colSpan="21" className="empty_records">No records found</td></tr>}
                                    </tbody>
                                </Table>
                            </div>
                            {arrdata.length > 0 ?
                                <div style={{ 'textAlign': 'center', 'padding': '25px' }}>
                                    <Button style={{ width: '150px' }} variant="primary" type="submit">Submit</Button>
                                </div>
                                : ''}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

// export default RiskHub;
const mapStateToProps = ({ riskinputs: { getrisksettings_suc } }) => ({
    getrisksettings_suc
})

const mapDispatchToProps = {
    getRiskSettingList: riskActions.getRiskSettingList
    // updateprofile: riskActions.updateprofile
}


export default connect(mapStateToProps, mapDispatchToProps)(RiskHub);