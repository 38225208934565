import { riskConstants } from "./constants";

import { STATUS_CHANGED_SUCCESSFULLY } from "./constants";
const axios = require("axios");
// const baseUrl = process.env.REACT_APP_BASE_U
const baseUrl = 'https://foresightapi.infrahub.co/'
 const baseUrl1 = 'http://localhost:8081/'



function addrisks(data) {
  return dispatch => {
    dispatch(request({ data }));
    console.log(data,"data from the risk");
    axios
      .post(baseUrl + "risks", 
        data
      )
      .then(function(response) { 
      
        console.log(response,"responce when we add the risk from zoho");
        if (response.data.status !== undefined && response.data.status === 1) {
          dispatch(success(response.data));
        } else {
          let message = response.data.message
            ? response.data.message
            : "Incorrect credentials";
          dispatch(failure(message));
        }
      })
      .catch(function(error) {
        console.log(error.message,"error .message");
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: riskConstants.ADDRISK_REQUEST, user };
  }
  function success(riskadd) {
    return { type: riskConstants.ADDRISK_SUCCESS, riskadd };
  }
  function failure(riskerror) {
    return { type: riskConstants.ADDRISK_FAILURE, riskerror };
  }
}

function getrisks(searchvalue, activePage, uid) { 
  return dispatch => {
    dispatch(request({}));
    // console.log((baseUrl+'risks/'+uid+'?page='+activePage+'&perpage=10&search='+searchvalue+'&sortBy=createdon&orderBy=DESC'),"===4444==")
    axios.get(baseUrl+'risks/'+uid+'?page='+activePage+'&perpage=10&search='+searchvalue+'&sortBy=createdon&orderBy=DESC',{headers:{"x-access-token":localStorage.getItem('token')}}).then(function(response) {
      if (response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error);
      dispatch(failure(error));
    });
  };

  function request(getrisk) {
    return { type: riskConstants.GETTABLERISK_REQUEST, getrisk };
  }
  function success(getrisksuc ) {
    return { type: riskConstants.GETTABLERISK_SUCCESS, getrisksuc };
  }
  function failure(getriskerror) {
    return { type: riskConstants.GETTABLERISK_FAILURE, getriskerror };
  }
}

function getFilteredrisks(uid,projecttype, projectname) { 
  console.log(projectname,"jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")
  return dispatch => {
    dispatch(request({}));
    axios.put(baseUrl+'risks/filterdatas/'+uid+'?projtype='+projecttype+'&project_name='+projectname).then(function(response) {
      console.log(response.data,"response.dataresponse.dataresponse.dataresponse.data")
   
      if (response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
 
      console.log(error,"filter error");
      dispatch(failure(error));
    });
  };

  function request(getrisk) {
    return { type: riskConstants.GETTABLERISK_REQUEST, getrisk };
  }
  function success(getrisksuc ) {
    return { type: riskConstants.GETTABLERISK_SUCCESS, getrisksuc };
  }
  function failure(getriskerror) {
    return { type: riskConstants.GETTABLERISK_FAILURE, getriskerror };
  }
}

function getFilteredrisksusers(uid,projecttype, projectname) { 
  return dispatch => {
    dispatch(request({}));
    axios.put(baseUrl+'user/filterdatasuser/'+uid+'?projtype='+projecttype+'&project_name='+projectname).then(function(response) {
        console.log(response.data,"response.data")
      if (response.data.status !== undefined && response.data.status === 1) {

        dispatch(success(response.data));
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error);
      dispatch(failure(error));
    });
  };

  function request(getrisk) {
    return { type: riskConstants.GETTABLERISK_REQUEST, getrisk };
  }
  function success(getrisksuc ) {
    return { type: riskConstants.GETTABLERISK_SUCCESS, getrisksuc };
  }
  function failure(getriskerror) {
    return { type: riskConstants.GETTABLERISK_FAILURE, getriskerror };
  }
}


function editrisk(riskid) {
  return dispatch => {
    
    dispatch(request({}));
    axios.get(baseUrl+'risks/edit/'+riskid).then(function(response) {
    
      if (response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
        console.log(response.data,"response from editttt");
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error);
      dispatch(failure(error));
    });
  };

  function request(editrisk) {
    return { type: riskConstants.EDITRISK_REQUEST, editrisk };
  }
  function success(editrisksuc ) {
    return { type: riskConstants.EDITRISK_SUCCESS, editrisksuc };
  }
  function failure(editriskerror) {
    return { type: riskConstants.EDITRISK_FAILURE, editriskerror };
  }
}

function updaterisk(data, riskid) {
  return dispatch => {
    dispatch(request({ data }));
    axios
      .patch(baseUrl + "risks/"+riskid, 
        data
      )
      .then(function(response) {
        console.log(response.data.status,"data in the upadte");
        if (response.data.status !== undefined && response.data.status === 1) {
          dispatch(success(response.data));
        } else {
          let message = response.data.message
            ? response.data.message
            : "Incorrect credentials";
          dispatch(failure(message));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(failure(error));
      });
  };

  function request(userudt) {
    return { type: riskConstants.UPDATERISK_REQUEST, userudt };
  }
  function success(riskudt) {
    return { type: riskConstants.UPDATERISK_SUCCESS, riskudt };
  }
  function failure(riskudterror) {
    return { type: riskConstants.UPDATERISK_FAILURE, riskudterror };
  }
}

function getProjectsList() {
  return dispatch => {
    dispatch(request({}));
    axios.get(baseUrl+'risks/projects/'+ localStorage.getItem('companyid'),{headers:{"x-access-token":localStorage.getItem('token')}}).then(function(response) {
      // console.log(response.data.status,'qqqqqqqqq55555');
      if (response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
      }
      else if(response.data.status == 5){
      
        dispatch({ type: STATUS_CHANGED_SUCCESSFULLY, payload: true })
    } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error);
      dispatch(failure(error));
    });
  };
  
  function request(getallprojectreq) {
      return { type: riskConstants.GETALLPROJECT_REQUEST, getallprojectreq };
  }
  function success(getallprojects ) {
      return { type: riskConstants.GETALLPROJECT_SUCCESS, getallprojects };
  }
  function failure(getallprojectserror) {
      return { type: riskConstants.GETALLPROJECT_FAILURE, getallprojectserror };
  }
}

function getRiskSettingList () { 
  return dispatch => {
    dispatch(request({}));
    axios.get(baseUrl+'risks/fetchrisksettings/risks/'+ localStorage.getItem('uid')).then(function(response) {
    
      if (response.data.status !== undefined && response.data.status === 1) {
        dispatch(success(response.data));
      } else {
        let message = response.data.message
          ? response.data.message
          : "Incorrect credentials";
        dispatch(failure(message));
      }
    })
    .catch(function(error) {
      console.log(error);
      dispatch(failure(error));
    });
  };
  
  function request(getrisksettingdetails) {
      return { type: riskConstants.GETRISKSETTINGS_REQUEST, getrisksettingdetails };
  }
  function success(getallsettings) {
      return { type: riskConstants.GETRISKSETTINGS_SUCCESS, getallsettings };
  }
  function failure(getallsettingserror) {
      return { type: riskConstants.GETRISKSETTINGS_FAILURE, getallsettingserror };
  }
}

export const riskActions = {
    addrisks, getrisks, getFilteredrisks,editrisk,getFilteredrisksusers, updaterisk, getProjectsList, getRiskSettingList
};
