import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FaWpforms, FaGlobe, FaUserTie, FaChartBar, FaChartLine } from 'react-icons/fa';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const baseUrl = 'https://foresightapi.infrahub.co/';
 const baseUrl1 = 'http://localhost:8081/'
const axios = require('axios');


function Sidebar() {
  const [open, setOpen] = React.useState(false);
  let getSelectpage = window.location.pathname;
  const userid = localStorage.getItem('uid');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const callapitocancel  =()=>{
    axios.get(baseUrl + 'index/unsubscribestripe/' + localStorage.getItem('uid'))
    .then(response=>{
      console.log(response,"")
      setOpen(false);
    }).catch((error)=>{
       console.log(error,"error")
    })
  }

  return (
    <Navbar expand="md" className="sidebar">
      <Navbar.Toggle aria-controls="sidebarToggleLeft" className="sidebarToggleBtn"><div><span></span></div></Navbar.Toggle>
      <Navbar.Collapse id="sidebarToggleLeft">
        <Nav activeKey={getSelectpage} as="ul" className="sidebarInner">
        <Nav.Item as="li">
            <Nav.Link href="/dashboard"><FaChartBar /> Dashboard</Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href="/allriskhubs"><FaGlobe />Risks Summary</Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href="/risks"><FaWpforms /> Risk Input</Nav.Link>
          </Nav.Item>
          { localStorage.getItem('role') === 'admin' ? <Nav.Item as="li">
            <Nav.Link href="/users"><FaUserTie /> Users</Nav.Link>
          </Nav.Item> : null }
          {/* {localStorage.getItem('role') === 'admin'?<Nav.Item as="li">
            <Nav.Link href="/users" onClick={handleClickOpen}><FaWpforms />Cancel Subscription</Nav.Link>
          </Nav.Item>:null} */}
          {/* <Nav.Item as="li">
            <Nav.Link onClick={handleClickOpen}><FaWpforms />Cancel Subscription</Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Navbar.Collapse>
       <div>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cancel subscription"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to cancel your subscription ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}  style={{backgroundColor:"#0F2869",color:"#ffffff"}} >Cancel</Button>
          <Button  style={{backgroundColor:"#0F2869",color:"#ffffff"}} onClick={callapitocancel} >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </Navbar>
  );
}

export default Sidebar;












// import React from 'react';
// import { Navbar, Nav } from 'react-bootstrap';
// import { FaWpforms, FaGlobe, FaUserTie, FaChartBar, FaChartLine } from 'react-icons/fa';

// function Sidebar() {
//   let getSelectpage = window.location.pathname;
//   return (
//     <Navbar expand="md" className="sidebar">
//       <Navbar.Toggle aria-controls="sidebarToggleLeft" className="sidebarToggleBtn"><div><span></span></div></Navbar.Toggle>
//       <Navbar.Collapse id="sidebarToggleLeft">
//         <Nav activeKey={getSelectpage} as="ul" className="sidebarInner">
//         <Nav.Item as="li">
//             <Nav.Link href="/dashboard"><FaChartBar /> Dashboard</Nav.Link>
//           </Nav.Item>
//           <Nav.Item as="li">
//             <Nav.Link href="/allriskhubs"><FaGlobe /> Risks Summary</Nav.Link>
//           </Nav.Item>
//           <Nav.Item as="li">
//             <Nav.Link href="/risks"><FaWpforms /> Risk Input</Nav.Link>
//           </Nav.Item>
//           { localStorage.getItem('role') === 'admin' ? <Nav.Item as="li">
//             <Nav.Link href="/users"><FaUserTie /> Users</Nav.Link>
//           </Nav.Item> : null }
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//   );
// }

// export default Sidebar;