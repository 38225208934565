import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Button, Toast, Spinner } from 'react-bootstrap'
import { FaUserEdit, FaUserAlt, FaEnvelopeOpenText, FaPhoneAlt, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import header from '../../components/header'
import sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Sidebar from '../../components/sidebar'
import moment from 'moment'
import Pagination from 'react-js-pagination'
import { myprofileActions } from '../../actions/myprofile'

const Myprofile = props =>
{
    const [firstname, setFirstname] = useState(localStorage.getItem('fname'))
    const [lastname, setLastname] = useState(localStorage.getItem('lname'))
    const [email, setEmail] = useState(localStorage.getItem('email'))
    const [phoneno, setPhoneno] = useState(localStorage.getItem('phoneno'))
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmpassword] = useState('')
    const [role, setRole] = useState(localStorage.getItem('role'))
    const [hidebtnload, setHideBtnLoad] = useState(true)
    const [isvalidate, setIsvalidate] = useState(false)
    const [showpwd, setShowPwd] = useState(false)
    const [showtoast, setShowToast] = useState(false)
    const [toastmsg, setToastMsg] = useState('')
    const [msgclass, setMsgClass] = useState('')
    const [show, setShow] = useState(false)

    const handleFieldValue = (e) =>
    {
        switch (e.target.name) {
            case 'firstname':
                return setFirstname(e.target.value)
            case 'lastname':
                return setLastname(e.target.value)
            case 'email':
                return setEmail(e.target.value)
            case 'phoneno':
                return setPhoneno(e.target.value)
            case 'password':
                return setPassword(e.target.value)
            case 'confirmpassword':
                return setConfirmpassword(e.target.value)
            case 'role':
                return setRole(e.target.value)
            default:
        }
    }
    const onChangeRole = (e) =>
    {
        setRole(e.target.value)
    }

    const toggleShow = () =>
    {
        setShowPwd(!showpwd)
    }
    const removeToast = () =>
    {
        setTimeout(() =>
        {
            setShowToast(false)
            setToastMsg('')
        }, 5000)
    }

    useMemo(() =>
    {
        if (props.profile_suc) {
            setShow(false)
            setShowToast(true)
            setToastMsg(props.profile_suc.message)
            setMsgClass('success')
            setHideBtnLoad(true)
            removeToast()
        }
    }, [props.profile_suc])

    const handleSubmit = (e) =>
    {
        e.preventDefault()
        setIsvalidate(true)
        if (firstname && lastname && phoneno) {
            let data = {
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "phoneno": phoneno,
                "password": password,
                "role": role,
            }
            setHideBtnLoad(false)
            props.updateprofile(data, localStorage.getItem('uid'))
        }
    }

    return (
        <div>
            <Header />
            <div className="black">
                <div className="pageContain">
                    <Sidebar />
                    <div className="content phoneBookContain">
                        <div className="pageTitleWrapper riskinput">
                            <Toast animation={false} show={showtoast} className={`toast_msgs ${msgclass}`}>
                                <Toast.Body>
                                    <strong className="mr-auto">{toastmsg}</strong>
                                </Toast.Body>
                            </Toast>
                            <div className="alert_custom_part">
                                <div className="pageTitleHeading deviceHeading">
                                    <span className="pageTitleHeadingIcon"><FaUserEdit /></span>
                            Edit Profile
                        </div>

                                <Form className="align_Form" onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>First Name*</Form.Label>
                                                <div className="inputWithIcon">
                                                    <FaUserAlt />
                                                    <Form.Control type="text" name="firstname" onChange={handleFieldValue} placeholder="Enter First Name" value={firstname} />
                                                </div>
                                                {isvalidate && firstname === '' ? <Form.Label className="validationError">Please enter first name</Form.Label> : null}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Last Name</Form.Label>
                                                <div className="inputWithIcon">
                                                    <FaUserAlt />
                                                    <Form.Control type="text" name="lastname" onChange={handleFieldValue} placeholder="Enter Last Name" value={lastname} />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Email</Form.Label>
                                                <div className="inputWithIcon">
                                                    <FaEnvelopeOpenText />
                                                    <Form.Control type="text" disabled name="usermail" onChange={handleFieldValue} placeholder="Enter Email Address" defaultValue={email} />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Phone Number*</Form.Label>
                                                <div className="inputWithIcon">
                                                    <FaPhoneAlt />
                                                    <Form.Control type="text" name="phoneno" onChange={handleFieldValue} placeholder="Enter Phone Number" value={phoneno} />
                                                </div>
                                                {isvalidate && phoneno === '' ? <Form.Label className="validationError">Please enter phone number</Form.Label> : null}
                                                {/* {this.state.isValidation && this.state.phoneno === '' ? <Form.Label id="phonenoerror" className="validationError validation_msg">Please enter phone number</Form.Label> : null}
                                            {this.state.phoneno && this.state.vaildPhone ? <Form.Label className="validationError validation_msg">Please enter only number.</Form.Label> : null}
                                            {this.state.phoneno && this.state.vaildPhoneLength ? <Form.Label className="validationError validation_msg">Please enter valid phone number.</Form.Label> : null} */}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Role</Form.Label>
                                                <div className="inputWithIcon">
                                                    <FaUserAlt />
                                                    <Form.Control disabled as="select" name="role" onChange={onChangeRole} defaultValue={role}>
                                                        <option value="admin">Admin</option>
                                                        <option value="user">User</option>
                                                    </Form.Control>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>New Password</Form.Label>
                                                <div className="inputWithIcon">
                                                    <FaLock />
                                                    <Form.Control type={showpwd ? 'text' : 'password'} placeholder="Password" name="password" onChange={handleFieldValue} value={password} />
                                                    <i className="eyeicon" onClick={toggleShow} >{showpwd ? <FaEyeSlash /> : <FaEye />}</i>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button className="updatemyprofilebutton" variant="primary" type="submit" block>Update profile</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ myprofile: { profile_suc } }) => ({
    profile_suc
})

const mapDispatchToProps = {
    getmyprofileData: myprofileActions.getmyprofileData,
    updateprofile: myprofileActions.updateprofile
}


export default connect(mapStateToProps, mapDispatchToProps)(Myprofile);
