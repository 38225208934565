import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Form, Table, Navbar, FormControl, Button, Modal, Toast, Row, Col, Spinner, Dropdown } from "react-bootstrap";
import { FaTrashAlt, FaGlobe, FaRegEdit, FaExclamationCircle, FaChartBar, FaFileExport } from "react-icons/fa";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import { riskActions } from '../../actions/riskinput'
import moment from 'moment'
import SelectSearch from 'react-select-search'
import Pagination from "react-js-pagination"
import { useHistory } from "react-router-dom"
import ImportModel from './import'
import { dashboard } from '../../actions/dashboard';
import axios from 'axios'
import LogoutModal from '../../components/Cancelsubscription'
// const axios = require("axios");
const baseUrl = 'https://foresightapi.infrahub.co/';
const baseUrl1 = 'http://localhost:8081/'
// http://localhost:8081/
// https://foresightapi.infrahub.co/
// const baseUrl1 = 'http://localhost:8081/'
const userid = localStorage.getItem('uid');
var zohoConn = localStorage.getItem('zoho_conn');



const Allriskhubs = props => {
    const [riskownvalue, setRiskOwnvalue] = useState('')
    const [userarray, setArray] = useState([{ value: '', name: '' }]);
    const history = useHistory()
    const [searchvalue, setSearchvalue] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [show, setShow] = useState(false);
    const [deleteid, setDeleteID] = useState('')
    const [showtoast, setShowToast] = useState(false);
    const [toastmsg, setToastMsg] = useState('');
    const [msgclass, setMsgClass] = useState('');
    const [hidebtnload, setHideBtnLoad] = useState(true)
    const [showImport, setDisplayImport] = useState(false)
    const [showRiskPopup, setRiskPopup] = useState(false)
    const [showValidData, setValidData] = useState([])
    const [showInValidData, setInValidData] = useState([])
    const [showExport, setShowWxport] = useState(false);




    // RiskJug Settings start
    const [projectName, setProjectName] = useState(true);
    const [projectNumber, setProjectNumber] = useState(true);
    const [riskId, setRiskId] = useState(true);
    const [riskorhazard, setRiskorHazard] = useState(true);
    const [cause, setCause] = useState(true);
    const [control1, setControl1] = useState(true);
    const [control2, setControl2] = useState(true);
    const [control3, setcontrol3] = useState(true);
    const [estimate, setEstimate] = useState(true);
    const [status, setStatus] = useState(true);
    const [category, setCategory] = useState(true);
    const [riskReporter, setRiskReporter] = useState(true);
    const [riskowner, setRiskOwner] = useState(true);
    const [date, setdate] = useState(true);
    const [realisationDate, setRealisationDate] = useState(true);
    const [probability, setProbability] = useState(true);
    const [lowCost, setLowcost] = useState(true);
    const [likelyCost, setLikelyCost] = useState(true);
    const [highCost, setHighCost] = useState(true);
    const [deterministicRisk, setDeterministicRisk] = useState(true);
    const [iteration, setIteration] = useState(true);
    const [showloading, setShowLoading] = useState(false)
    const userrole = localStorage.getItem('role');
    // RiskJug Settings ends

    //New updates
    const [risksources, setrisksources] = useState(true)
    const [riskcategories, setriskcategories] = useState(true)
    const [mitigationstatus, setmitigationstatus] = useState(true)
    const [mitigationPlannedClosureDate, setmitigationPlannedClosureDate] = useState(true)
    const [mitigationActualClosureDate, setmitigationActualClosureDate] = useState(true)
    const [contingencystatus, setcontingencystatus] = useState(true)
    const [contingencyPlannedClosureDate, setcontingencyPlannedClosureDate] = useState(true)
    const [contingencyActualClosureDate, setcontingencyActualClosureDate] = useState(true)
    const [actionImplemented, setactionImplemented] = useState(true)
    const [closureDate, setclosureDate] = useState(true)
    const [remarks, setremarks] = useState(true)
    const [impact, setimpact] = useState(true)
    const [riskExposure, setriskExposure] = useState(true)
    const [risklevel, setrisklevel] = useState(true)
    const [userDefinedfields, setuserDefinedfields] = useState(true)
    const [pageloader, setPageLoader] = useState(false)
    const [pnumber, setPnumber] = useState('');
    const [projtype, setProjtype] = useState('direct');
    const [projname, setProjname] = useState('');
    const [projectClassname, setProjectClassname] = useState('projectNameField')
    const [logouloder, setlogouloder] = useState(false)
    //

    useMemo(() => {
        if (props.getrisksettings_suc && props.getrisksettings_suc.data != null) {
            var objarray = [];
            objarray = Object.entries(props.getrisksettings_suc.data);
            objarray.length = 35
            setProjectName(objarray[0][1])
            setProjectNumber(objarray[1][1])
            setRiskId(objarray[2][1])
            setRiskorHazard(objarray[3][1])
            setCause(objarray[4][1])
            setControl1(objarray[5][1])
            setControl2(objarray[6][1])
            setcontrol3(objarray[7][1])
            setEstimate(objarray[8][1])
            setStatus(objarray[9][1])
            setCategory(objarray[10][1])
            setRiskReporter(objarray[11][1])
            setRiskOwner(objarray[12][1])
            setdate(objarray[13][1])
            setRealisationDate(objarray[14][1])
            setProbability(objarray[15][1])
            setLowcost(objarray[16][1])
            setLikelyCost(objarray[17][1])
            setHighCost(objarray[18][1])
            setDeterministicRisk(objarray[19][1])
            setIteration(objarray[20][1])
            setrisksources(objarray[21][1])
            setriskcategories(objarray[22][1])
            setimpact(objarray[23][1])
            setrisklevel(objarray[24][1])
            setriskExposure(objarray[25][1])
            setmitigationstatus(objarray[26][1])
            setcontingencystatus(objarray[27][1])
            setactionImplemented(objarray[28][1])
            setremarks(objarray[29][1])
            setmitigationPlannedClosureDate(objarray[30][1])
            setmitigationActualClosureDate(objarray[31][1])
            setcontingencyPlannedClosureDate(objarray[32][1])
            setcontingencyActualClosureDate(objarray[33][1])
            setclosureDate(objarray[34][1])

        }
    }, [props.getrisksettings_suc])

    const getApiTest = async () => {
        try {
            let valueTest = await axios.get(baseUrl + 'risks/projects/' + localStorage.getItem('companyid'), { headers: { "x-access-token": localStorage.getItem('token') } })

            if (valueTest.data.status == 5) {
                setlogouloder(true)
            }

        } catch (error) {
            console.log({ error: error.message })
        }
    }


    useEffect(() => {
        if (localStorage.getItem('uid') === null) {
            return window.location = '/';
        }
    }, [])

    useEffect(() => {
        getApiTest();
        if (localStorage.getItem('uid') === null) {
            return window.location = '/';
        } else {
            // one month subscription
            // var subEndDate = new Date(localStorage.getItem('subscriptionEndDate'));
            // if (subEndDate >= new Date()) {
            props.getProjects()
            // } else {
            //     props.getProjects()
            // }
        }
    }, [])
    useEffect(() => {
        if (localStorage.getItem('role') === 'admin') {
            if (props.getall_risk_suc) {

                if (props.getall_risk_suc.data.length > 0) {
                    props.getProjectData(props.getall_risk_suc.data[0].project_name)

                    setPageLoader(true)
                    setPnumber(props.getall_risk_suc.data[0]._id)
                    setArray(props.getall_risk_suc.data.map(items => {

                        return {
                            value: items.project_name,
                            name: items.project_name,
                            id: items._id
                        }
                    }))

                }
            }
        }
        // else{
        //     axios.get(`http://localhost:8081/user/testapi?role=${localStorage.getItem('role')}&companyId=${localStorage.getItem('companyid')}&type=directProject`).then(function (response) {
        //         // console.log(response.data.list[0],"1111111111");
        //     // setPnumber(response.data.list[0].id)
        //     setArray(response.data.list.map((items, index) => {

        //             return {
        //                 value: items.label,
        //                 name: items.label,
        //                 id:items.id

        //             }
        //         }))


        //     })
        // }
    }, [props.getall_risk_suc])


    const removeToast = () => {
        setTimeout(() => {
            setShowToast(false)
            setToastMsg('')
        }, 5000)
    }
    const handleClose = () => setShow(false)
    const getTabelData = () => {

        // one month subscription
        // var subEndDate = new Date(localStorage.getItem('subscriptionEndDate'));
        // if (subEndDate >= new Date()) {
        props.getRiskSettingList(localStorage.getItem('uid'))
        props.getTblData(searchvalue, activePage, localStorage.getItem('uid'))
        // } else {
        //     history.push('/subscriptionhasended')
        // }
    }

    useEffect(() => {
        getTabelData(searchvalue, activePage)
    }, [])

    const handleSearch = (e) => {
        setSearchvalue(e.target.value)
    }

    const searchDevice = () => {
        getTabelData()
    }

    const clearDevice = () => {
        setSearchvalue('')
        props.getTblData('', activePage, localStorage.getItem('uid'))
    }

    const editRisk = (id) => {
        history.push('/risks?token=' + id)
    }

    const redirect = () => {
        history.push('/risks')
    }

    const showhistograph = (id) => {
        history.push('/histography?token=' + id)
    }

    const handlePageChange = (pagenum) => {
        setActivePage(pagenum)
        props.getTblData(searchvalue, pagenum, localStorage.getItem('uid'))
    }

    const handleKeyPress = (target) => {
        if (target.charCode == 13) {
            target.preventDefault()
            getTabelData()
        }
    }

    const handleDeleteShow = (id) => {
        setShow(true)
        setDeleteID(id)
    }
    const onDelete = () => {
        axios.delete(baseUrl + 'risks/' + deleteid)
            .then(response => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                setShow(false)
                setShowToast(true)
                setToastMsg(response.data.message)
                setMsgClass('success')
                setHideBtnLoad(true)
                removeToast()
                props.getTblData(searchvalue, activePage, localStorage.getItem('uid'))
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleImportPopUp = (type) => {
        setDisplayImport(type)
    }

    const closeRiskPopup = () => {
        setRiskPopup(false)
        setValidData([])
        setInValidData([])
    }
    const importSubmit = (some) => {
        setShowLoading(true)
        const data = new FormData()
        data.append('file', some);
        data.append('companyid', localStorage.getItem('companyid'))
        data.append('createdby', localStorage.getItem('uid'))
        axios.post(baseUrl + 'risks/importRisks', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                // console.log(response.data.data,"responce after importing the file")
                setDisplayImport(false);
                let invalidRisks = []
                let validRisks = []
                response.data.data.invalid.filter((obj) => {
                    invalidRisks.push(obj[2])
                })
                response.data.data.valid.filter((obj) => {
                    validRisks.push(obj[2])
                })
                if (invalidRisks != "" && validRisks == "") {
                    setInValidData(invalidRisks)
                }
                if (invalidRisks == "" && validRisks != "") {
                    setValidData(validRisks)
                }
                if (invalidRisks != "" && validRisks != "") {
                    setValidData(validRisks)
                    setInValidData(invalidRisks)
                }
                if (invalidRisks == "" && validRisks == "") {
                    setValidData([])
                    setInValidData([])
                }
                setRiskPopup(true)
                setShowLoading(false)
                props.getTblData(searchvalue, activePage, localStorage.getItem('uid'))
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handelImport = () => {
        setShowWxport(true)
    }
    const closeExport = () => {
        setShowWxport(false)
    }
    const exportRisks = (ev) => {
        setShowWxport(false)
        window.open(baseUrl + 'risks//download/export/data/' + localStorage.getItem('uid') + '/' + ev)
    }

console.log(showloading,"setShowLoading(false)");

    const setData =  (data) => {
         setShowLoading(true)
         setTimeout(() => {
            if (localStorage.getItem('role') === 'admin') {
                props.getProjectData(data)
                // console.log(data,"jjjjjjjjjjjjjjjjj")
                props.getFilterData(localStorage.getItem('uid'), projtype, data)
                // console.log(data,"jjjjjjjjjjjjjjjjj")
    
                let projnumber = pnumber;
                userarray.filter(function (id) {
                    if (id.value == data) {
                        projnumber = id.id
                    }
                })
                setPnumber(projnumber)
                setProjname(data)
                setRiskOwnvalue(data)
                setPageLoader(true)
                setShowLoading(false)
            } else {
                props.getProjectData(data)
                props.getFilterDatausers(localStorage.getItem('uid'), projtype, data)
                let projnumber = pnumber;
                userarray.filter(function (id) {
                    if (id.value == data) {
                        projnumber = id.id
                    }
                })
                setPnumber(projnumber)
                setProjname(data)
                setRiskOwnvalue(data)
                setPageLoader(true)
                 setShowLoading(false)
            }
          }, 1000);
      
    }
    const onChangeStatus = (e) => {
        setProjtype(e.target.value)

        if (e.target.value == 'direct') {
            // console.log("ttt")
            if (localStorage.getItem('role') === 'admin') {
                props.getProjects()
            }
            //     else{
            //         axios.get(`http://localhost:8081/user/testapi?role=${localStorage.getItem('role')}&companyId=${localStorage.getItem('companyid')}&type=directProject`).then(function (response) {
            //        setArray(response.data.list.map((items, index) => {

            //         return {
            //             value: items.label,
            //             name: items.label,
            //             id:items.id
            //         }
            //     }))
            // })
            //     }

        } else {
            if (localStorage.getItem('role') === 'admin') {
                setPageLoader(true)
                var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN')
                axios.get(baseUrl + 'index/zohoprojects/' + localStorage.getItem('uid'), { headers: { authorization: AuthStr } })
                    .then(response => {
                        setArray(response.data.data.projects.map(items => {
                            return {
                                value: items.name,
                                name: items.name,
                                id: items.id_string
                            }
                        }))
                        // setRiskOwnvalue('')
                        setData(response.data.data.projects[0].name)
                        setProjname(response.data.data.projects[0].name)
                        setPnumber(response.data.data.projects[0].id_string)
                        setPageLoader(false)
                    }).catch(function (error) {
                        console.log(error);
                    })
            }
            //     else{

            //         axios.get(`http://localhost:8081/user/testapi?role=${localStorage.getItem('role')}&companyId=${localStorage.getItem('companyid')}&type=zohoProject`).then(function (response) {
            //     setArray(response.data.list.map((items, index) => {

            //         return {
            //             value: items.label,
            //             name: items.label,
            //             id:items.pnumber,
            //         }
            //     }))
            //     console.log(response.data.list[0].label,"in userrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
            //     setData(response.data.list[0].label)
            //     setProjname(response.data.list[0].label)
            //     setPnumber(response.data.list[0].pnumber)



            // }).catch(function (error) {
            //     console.log(error);
            // })
            //     }



        }

    }


// console.log(projtype,"//////",riskownvalue,"57497ufsvt24tv489t")
    return (
        <div className="black">
            {logouloder && <LogoutModal />}
            <Header />
            <div className="pageContain">
                <Sidebar />
                <div className="contentWrapper for_modal">
                    <div className="content all_risk_hub riskinput">

                        <Toast animation={false} show={showtoast} className={`toast_msgs ${msgclass}`}>
                            <Toast.Body>
                                <strong className="mr-auto">{toastmsg}</strong>
                            </Toast.Body>
                        </Toast>
                        <div className="dashb_select">
                            <div className="pageTitleHeading">
                                <span className="pageTitleHeadingIcon"><FaGlobe />
                                </span>
                                All Risk Hubs
                            </div>

                            {/* filter and search */}
                            <div className='header-dropdown-btns' style={{ float: 'right' }}>
                                <div style={{ 'marginLeft': '0px !important', 'maxWidth': '10% !important' }} className="float_select ">Project Type</div>
                                <div style={{ 'minWidth': 250, marginLeft: 10 }} className="float_select">
                                    <Form.Control as="select" onChange={onChangeStatus} defaultValue={projtype} value={projtype}  >
                                        <option value="direct" className="float_select" >Direct Projects</option>
                                        {/* {(zohoConn == 'false' || zohoConn == false) ?
                                            <option disabled value="zoho">Zoho Projects</option>
                                            : <option value="zoho">Zoho Projects</option>} */}

                                        {/* <option disabled value="zoho">Zoho Projects</option> */}
                                        <option value="zoho">Zoho Projects</option>
                                    </Form.Control>
                                </div>
                                <div style={{ 'marginLeft': '0px !important', 'maxWidth': '10% !important' }} className="float_select ">Project Name</div>
                                <div style={{ 'minWidth': 100, marginLeft: 10 }} className="float_select">
                                    <SelectSearch onChange={setData} defaultValue={projtype} value={riskownvalue} options={userarray} search emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>Not found </div>} />
                                </div>
                            </div>


                            {/* **** */}

                            <Navbar bg="light" variant="light" className="device_navbar">
                                <Form inline>
                                    <FormControl type="text" placeholder="Search" name="searchvalue" id="usersearch" onChange={handleSearch} value={searchvalue} className="mr-sm-2" onKeyPress={handleKeyPress} />
                                    <Button variant="outline-primary" onClick={() => searchDevice()} >Search</Button>
                                    <Button variant="outline-primary userclear" onClick={() => clearDevice()} >Clear</Button>
                                    <>
                                        <Dropdown style={{ padding: '10px' }} drop='down'>
                                            <Dropdown.Toggle variant="outline-primary userclear" id="dropdown-basic">
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="/riskhub">Column Settings</Dropdown.Item>
                                                <Dropdown.Item onClick={(condition) => handleImportPopUp(true)}>Import</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handelImport()}>Export</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                </Form>
                                <Button title="Add Risk Input" variant="link" onClick={() => redirect()} className="text-primary btn_redirection">Add Risk Input</Button>
                            </Navbar>

                            {showRiskPopup ?
                                <Modal.Dialog className={`toast_msgs success`}>
                                    <Modal.Header>
                                        <Modal.Title>Import Risks</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            {showValidData.length > 0 ?
                                                <div >
                                                    <Row>
                                                        <div>
                                                            <b style={{ color: 'green' }}>The following RiskIds are Valid and imported</b>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div style={{ textAllign: 'center', marginLeft: '200px' }}>
                                                            {showValidData.map((data, index) => {
                                                                return <p style={{ lineHeight: '10px' }} key={index}>{data}</p>
                                                            })}
                                                        </div>
                                                    </Row>
                                                </div>
                                                : ''}

                                            {showInValidData.length > 0 ?
                                                <div >
                                                    <Row>
                                                        <div style={{ color: 'red' }}>
                                                            <b>The following RiskIds are InValid, please check them again</b>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div style={{ textAllign: 'center', marginLeft: '200px' }}>
                                                            {showInValidData.map((data, index) => {
                                                                return <p style={{ lineHeight: '10px' }} key={index} >{data}</p>
                                                            })}
                                                        </div>
                                                    </Row>
                                                </div>
                                                : ''}

                                            {showValidData.length === 0 && showInValidData.length === 0 ?
                                                <div>
                                                    <Row>
                                                        No record was imported
                                                    </Row>
                                                </div>
                                                : ''
                                            }
                                            {/* <Row>
                                        The following RiskIds are valid and imported 
                                        The following RiskIds are invalid 
                                    </Row>
                                    <Row>
                                        No data was imported
                                    </Row> */}
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer className="padding0">
                                        <Button variant="link" onClick={closeRiskPopup}>Close</Button>
                                    </Modal.Footer>
                                </Modal.Dialog>
                                : ''}

                            {showImport ? <ImportModel handleShow={(condition) => { handleImportPopUp(condition) }} importSubmit={(condition) => { importSubmit(condition) }}>Import</ImportModel> : ''}
                            {showloading ?
                                <div style={{ position: 'absolute', margin: '110px auto', textAlign: 'center', right: '0px', left: '0px' }}>
                                    <Spinner animation="grow" variant="primary" />
                                    <Spinner animation="grow" variant="secondary" />
                                    <Spinner animation="grow" variant="success" />
                                    <Spinner animation="grow" variant="danger" />
                                </div>
                                : ''}
                        </div>
                        {/* delete model */}
                        <Modal className="delete_popup" show={show}>
                            <Modal.Body>
                                <FaExclamationCircle />
                                <h3>Are you sure you want to delete this risk?</h3>
                                <p>You won't be able to revert this!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                                <Button variant="primary" onClick={onDelete}>Yes, delete it!</Button>
                            </Modal.Footer>
                        </Modal>
                        {/* Export model */}
                        <Modal size="l" style={{ textAlign: 'center', minHeight: '300px' }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={showExport} onHide={closeExport}>
                            <Modal.Header closeButton>
                                <Modal.Title>Export Risks</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FaFileExport />
                                <h3>Choose Export Type</h3>
                            </Modal.Body>
                            <Modal.Footer style={{ justifyContent: 'center' }}>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <Button variant="primary" onClick={() => exportRisks('all')}>
                                            <span style={{ padding: '10px' }}>Export all the columns</span>
                                        </Button>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <Button variant="primary" onClick={() => exportRisks('coloumn')}>
                                            <span style={{ padding: '10px' }}>Export Displaying Columns</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </Modal>
                        <div className="table_scroll">
                            <Table striped bordered hover responsive className="defaultTable">
                                <thead>
                                    <tr>
                                        {projectName ? <th className="center_align width150">Project Name</th> : ''}
                                        {projectNumber ? <th className="center_align width110">Project #</th> : ''}
                                        {riskId ? <th className="center_align width110">Risk ID</th> : ''}
                                        {riskorhazard ? <th className="center_align width200">Risk Description</th> : ''}
                                        {cause ? <th className="center_align width200">Cause</th> : ''}
                                        {control1 ? <th className="center_align width200">Mitigation Plan</th> : ''}
                                        {control2 ? <th className="center_align width200">Contingency Plan</th> : ''}
                                        {control3 ? <th className="center_align width150">Control 3</th> : ''}
                                        {estimate ? <th className="center_align width200">Basis of Estimate</th> : ''}
                                        {status ? <th>Status</th> : ''}
                                        {category ? <th>Category</th> : ''}
                                        {riskReporter ? <th className="center_align width150">Identified By</th> : ''}
                                        {riskowner ? <th className="center_align width200">Person Responsible</th> : ''}
                                        {date ? <th className="center_align width200">Identified Date</th> : ''}
                                        {realisationDate ? <th className="center_align width300">Due/Expected Closure Date</th> : ''}
                                        {probability ? <th className="center_align">Probability</th> : ''}
                                        {lowCost ? <th className="center_align width110">Low Cost</th> : ''}
                                        {likelyCost ? <th className="center_align width150">Likely Cost</th> : ''}
                                        {highCost ? <th className="center_align width110">High Cost</th> : ''}
                                        {deterministicRisk ? <th className="center_align width200">Deterministic Risk</th> : ''}
                                        {iteration ? <th className="center_align width150"># of Iterations</th> : ''}
                                        {risksources ? <th className="center_align width150">Risk Sources</th> : ''}
                                        {riskcategories ? <th className="center_align width150">Risk Categories</th> : ''}
                                        {risklevel ? <th className="center_align width150">Risk Level</th> : ''}
                                        {impact ? <th className="center_align width150">Impact</th> : ''}
                                        {riskExposure ? <th className="center_align width150">Risk Exposure</th> : ''}
                                        {mitigationPlannedClosureDate ? <th className="center_align width300">Mitigationplanned ClosureDate</th> : ''}
                                        {mitigationActualClosureDate ? <th className="center_align width300">Mitigationactual ClosureDate</th> : ''}
                                        {mitigationstatus ? <th className="center_align width200">Mitigation Status</th> : ''}
                                        {contingencyPlannedClosureDate ? <th className="center_align width300">Contingencyplanned ClosureDate</th> : ''}
                                        {contingencyActualClosureDate ? <th className="center_align width300">Contingencyactual ClosureDate</th> : ''}
                                        {contingencystatus ? <th className="center_align width200">Contingency Status</th> : ''}
                                        {actionImplemented ? <th className="center_align width200">Action Implemented</th> : ''}
                                        {closureDate ? <th className="center_align width150">Closure Date</th> : ''}
                                        {remarks ? <th className="center_align width150">Remarks</th> : ''}

                                        {projectName || projectNumber || riskId || riskorhazard || cause || control1 || control2 || control3 || estimate ||
                                            status || category || riskReporter || riskowner || date || realisationDate || probability ||
                                            lowCost || likelyCost || highCost || deterministicRisk || iteration || risksources || riskcategories || risklevel || riskExposure || mitigationPlannedClosureDate || mitigationActualClosureDate || mitigationstatus || contingencyPlannedClosureDate || contingencyActualClosureDate || contingencystatus || impact || actionImplemented || closureDate || remarks ? <th className="center_align width110">Actions</th> : ''}

                                        {projectName || projectNumber || riskId || riskorhazard || cause || control1 || control2 || control3 || estimate ||
                                            status || category || riskReporter || riskowner || date || realisationDate || probability ||
                                            lowCost || likelyCost || highCost || deterministicRisk || iteration || risksources || riskcategories || risklevel || riskExposure || mitigationPlannedClosureDate || mitigationActualClosureDate || mitigationstatus || contingencyPlannedClosureDate || contingencyActualClosureDate || contingencystatus || impact || actionImplemented || closureDate || remarks ? '' : <th className="center_align width110">Enable Column in Settings to Display</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    { props.tbl_rsk_suc ? props.tbl_rsk_suc.Records.length > 0 ? props.tbl_rsk_suc.Records.map((deatils, i) => (
                                        // console.log(deatils,"hhhhhhhhhhhh"),          
                                        <tr key={i}>
                                            {projectName ? <td>{deatils.project_name}</td> : ''}
                                            {projectNumber ? <td className="center_align">{deatils.project_number}</td> : ''}
                                            {riskId ? <td className="center_align">{deatils.riskid}</td> : ''}
                                            {riskorhazard ? <td>{deatils.risk}</td> : ''}
                                            {cause ? <td>{deatils.cause}</td> : ''}
                                            {control1 ? <td>{deatils.control1}</td> : ''}
                                            {control2 ? <td>{deatils.control2}</td> : ''}
                                            {control3 ? <td>{deatils.control3}</td> : ''}
                                            {estimate ? <td>{deatils.estimate}</td> : ''}
                                            {status ? <td>{deatils.status}</td> : ''}
                                            {category ? <td>{deatils.category}</td> : ''}
                                            {riskReporter ? <td>{(deatils.reporter ? deatils.reporter.firstname + ' ' + deatils.reporter.lastname : '--')}</td> : ''}
                                            {riskowner ? <td>{(deatils.owner ? deatils.owner.firstname + ' ' + deatils.owner.lastname : '--')}</td> : ''}
                                            {date ? <td className="center_align">{moment(deatils.risk_date).format("DD-MM-YYYY")}</td> : ''}
                                            {realisationDate ? <td className="center_align">{moment(deatils.realisation_date).format("DD-MM-YYYY")}</td> : ''}
                                            {probability ? <td className="center_align">{(deatils.probability * 100) + ' %'}</td> : ''}
                                            {lowCost ? <td className="right_align">{deatils.lowcost}</td> : ''}
                                            {likelyCost ? <td className="right_align">{deatils.likelycost}</td> : ''}
                                            {highCost ? <td className="right_align">{deatils.highcost}</td> : ''}
                                            {deterministicRisk ? <td>{Math.round(deatils.deterministicrisk)}</td> : ''}
                                            {iteration ? <td className="center_align">{deatils.iterations}</td> : ''}
                                            {risksources ? <td className="center_align">{deatils.risksource}</td> : ''}
                                            {riskcategories ? <td className="center_align">{deatils.riskcatagory}</td> : ''}
                                            {risklevel ? <td className="center_align">{deatils.risklevel}</td> : ''}
                                            {impact ? <td className="center_align">{deatils.impact}</td> : ''}
                                            {riskExposure ? <td className="center_align">{deatils.riskexposure}</td> : ''}
                                            {mitigationPlannedClosureDate ? <td className="center_align">{moment(deatils.mitigationplannedclosure).format("DD-MM-YYYY")}</td> : ''}
                                            {mitigationActualClosureDate ? <td className="center_align">{moment(deatils.mitigationactualclosure).format("DD-MM-YYYY")}</td> : ''}
                                            {mitigationstatus ? <td className="center_align">{deatils.mitigationstatus}</td> : ''}
                                            {contingencyPlannedClosureDate ? <td className="center_align">{moment(deatils.contingencyplannedclosuredate).format("DD-MM-YYYY")}</td> : ''}
                                            {contingencyActualClosureDate ? <td className="center_align">{moment(deatils.contingencyactualclosuredate).format("DD-MM-YYYY")}</td> : ''}
                                            {contingencystatus ? <td className="center_align">{deatils.contingencystatus}</td> : ''}
                                            {actionImplemented ? <td className="center_align">{deatils.actionimplemented}</td> : ''}
                                            {closureDate ? <td className="center_align">{moment(deatils.closuredate).format("DD-MM-YYYY")}</td> : ''}
                                            {remarks ? <td className="center_align">{deatils.remarks}</td> : ''}
                                            {projectName || projectNumber || riskId || riskorhazard || cause || control1 || control2 || control3 || estimate ||
                                                status || category || riskReporter || riskowner || date || realisationDate || probability ||
                                                lowCost || likelyCost || highCost || deterministicRisk || iteration || risksources || riskcategories || risklevel || riskExposure ||
                                                mitigationPlannedClosureDate || mitigationActualClosureDate || mitigationstatus || contingencyPlannedClosureDate || contingencyActualClosureDate || contingencystatus || impact || actionImplemented || closureDate || remarks ?
                                                <td className="center_align">
                                                    <div className="actionBtns">
                                                        {((userrole == 'admin') || ((userrole == 'user') && ((deatils.reporter && userid == deatils.reporter._id) || (deatils.owner && userid == deatils.owner._id) || userid == deatils.createdby))) ?
                                                            <><Button title="Edit" variant="link" onClick={() => editRisk(deatils._id)} className="text-primary"><FaRegEdit /></Button>
                                                                <Button title="Delete" onClick={() => handleDeleteShow(deatils._id)} variant="link" className="text-danger" ><FaTrashAlt /></Button></>
                                                            : ''}
                                                    </div>
                                                </td> : ''}
                                                </tr>
                                        
                                    )) : <tr><td colSpan="21" className="empty_records">No records found123</td></tr> : <tr><td colSpan="21" className="empty_records">No records found</td></tr>}
                                            
                                </tbody>
                            </Table>
                            {props.tbl_rsk_suc && props.tbl_rsk_suc.Count > 0 ? <Pagination activePage={activePage} itemsCountPerPage={10} totalItemsCount={props.tbl_rsk_suc ? props.tbl_rsk_suc.Count > 0 ? props.tbl_rsk_suc.Count : 0 : 0} pageRangeDisplayed={5} onChange={handlePageChange} /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ riskinputs: { tbl_rsk_suc, getrisksettings_suc,
    getall_risk_suc } }) => ({
        tbl_rsk_suc, getrisksettings_suc, getall_risk_suc
    })

// const mapStateToProps = ({ riskinputs: { tbl_rsk_suc, getrisksettings_suc } }) => ({
//     tbl_rsk_suc, getrisksettings_suc
// })

const mapDispatchToProps = {
    getTblData: riskActions.getrisks,
    getProjects: riskActions.getProjectsList,
    getProjectData: dashboard.getProjectData,
    getRiskSettingList: riskActions.getRiskSettingList,
    getFilterData: riskActions.getFilteredrisks,
    getFilterDatausers: riskActions.getFilteredrisksusers,
}

// getTblData: riskActions.getrisks,
// getRiskSettingList: riskActions.getRiskSettingList
//   getProjectData: dashboard.getProjectData,
export default connect(mapStateToProps, mapDispatchToProps)(Allriskhubs);