import React, { useState, useMemo, useEffect } from 'react'
import Header from "../../components/header"
import Sidebar from "../../components/sidebar"
import { Form, Row, Col, Button, Toast, Spinner, Navbar, FormControl, Table, Modal } from 'react-bootstrap'
import { FaUserAlt, FaEnvelopeOpenText, FaPhoneAlt, FaLock, FaUsers, FaRegEdit, FaTrashAlt, FaExclamationCircle, FaEye, FaEyeSlash } from 'react-icons/fa'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import { riskActions } from '../../actions/riskinput'
import { connect } from 'react-redux'
import SelectSearch from 'react-select-search';
import Multiselect from 'multiselect-react-dropdown';
import { usersActions } from '../../actions/users'
import Pagination from "react-js-pagination"
import { useHistory } from "react-router-dom"
import LogoutModal from '../../components/Cancelsubscription'

import axios from 'axios'
// const axios = require("axios");
const baseUrl = 'https://foresightapi.infrahub.co/'
// http://localhost:8081/
 const baseUrl1 = 'http://localhost:8081/'

const Users = props => {
  const history = useHistory()
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phoneno, setPhoneno] = useState('')
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmpassword] = useState('')
  const [role, setRole] = useState('admin')
  const [hidebtnload, setHideBtnLoad] = useState(true)
  const [isvalidate, setIsvalidate] = useState(false)
  const [showtoast, setShowToast] = useState(false);
  const [toastmsg, setToastMsg] = useState('');
  const [msgclass, setMsgClass] = useState('');
  const [userarray, setArray] = useState([{ label: '', name: "Direct" }]);
  const [edituid, setEditUID] = useState();
  const [searchvalue, setSearchvalue] = useState('')
  const [activePage, setActivePage] = useState(1)
  const [show, setShow] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false)
  const [formType, setFormType] = useState('add')
  const [deleteid, setDeleteID] = useState();
  const [emailvalidate, setEmailvalidate] = useState(false);
  const [pwdlength, setPwdlength] = useState(false);
  const [pwdmismatch, setPwdMismatch] = useState(false);
  const [isvaildate, setIsvaildate] = useState(false);
  const [showpwd, setShowPwd] = useState(false)
  const [projecttype, setprojecttype] = useState("Direct Projects")
  const [selectproject, setselectproject] = useState([])

  // new update
  const [logouloder, setlogouloder] = useState(false)

  const getApiTest = async () => {
    try {
        let valueTest = await axios.get(baseUrl+'risks/projects/'+ localStorage.getItem('companyid'),{headers:{"x-access-token":localStorage.getItem('token')}})

        if (valueTest.data.status == 5) {
            setlogouloder(true)
        }

    } catch (error) {
        console.log({ error: error.message })
    }
}
  useEffect(() => {
    getApiTest();
    if (localStorage.getItem('uid') === null) {
      return window.location = '/';
    } else {
      // one month subscription
      //  var subEndDate = new Date(localStorage.getItem('subscriptionEndDate'));
      //  if(subEndDate >= new Date() ){
      props.getusers(searchvalue, activePage)
      //  }else{
      //   history.push('/subscriptionhasended')
      //  }

    }
  }, [])

  useEffect(() => {
    var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN')
    axios.get(baseUrl + 'risks/projects/' + localStorage.getItem('companyid'),{headers:{"x-access-token":localStorage.getItem('token')}}).then(function (response) {
      if(response.data.length>0){
        setArray(response.data.data.map(items => {
          return {
            label: items.project_name,
            name: "Direct",
            id: items._id
          }
        }))
      }
      

    })
  }, [])

  var dtatamap = [];
  const arrayempty = [
    {
      'iterations': 100000,
      'project_name': "Rail Project Commercial Risks",
      'project_number': "14595650000028190052",
      'riskid': 27,
      '_id': "6034c5882f722261484edab6",
    },
    {
      'iterations': 100000,
      'project_name': "Rail Project Commercial Risks1",
      'project_number': "14595650000028190053",
      'riskid': 28,
      '_id': "6034c5882f722261484edab6",
    },
    {
      'iterations': 100000,
      'project_name': "Rail Project Commercial Risks2",
      'project_number': "14595650000028190054",
      'riskid': 29,
      '_id': "6034c5882f722261484edab6",
    },
  ]


  var dtatamap = [];
  dtatamap = arrayempty.map(item => {
    return {
      label: item.project_name,
    }
  })
  const handleSearch = (e) => {
    setSearchvalue(e.target.value)
  }
  useEffect(() => {
    props.getusers(searchvalue, activePage)
  }, [])

  const searchDevice = () => {
    console.log(searchvalue, activePage,"searchvalue, activePage");
    props.getusers(searchvalue, activePage)

  }
  const handleClose = () => {
    setShow(false)
    setDeletePopup(false)
  }
  const toggleShow = () => {
    setShowPwd(!showpwd)
  }

  const handleDeleteShow = (id) => {
    setDeletePopup(true)
    setDeleteID(id)
  }
  const handleShow = (type, user) => {
    // console.log(user,"====");
    setShow(true)
    setFormType(type)
    if (type == 'edit') {
      setEditUID(user._id)
      setFirstname(user.firstname)
      setLastname(user.lastname)
      setEmail(user.email)
      setPhoneno(user.phoneno)
      setRole(user.role)
      // setValue(user.project_name.label)
    } else {
      setEditUID('')
      setFirstname('')
      setLastname('')
      setEmail('')
      setPhoneno('')
      setRole('admin')
      setPassword('')
      setConfirmpassword('')
    }
  }

  const clearDevice = () => {
    setSearchvalue('')
    props.getusers('', activePage)
  }


  const mutiselectproject = (value) => {
    setselectproject(value)
  }

  const handleFieldValue = (e) => {
    // console.log(e.target.name,"pppp");
    switch (e.target.name) {
      case 'firstname':
        return setFirstname(e.target.value)
      case 'lastname':
        return setLastname(e.target.value)
      case 'email':
        return setEmail(e.target.value)
      case 'phoneno':
        return setPhoneno(e.target.value)
      case 'password':
        return setPassword(e.target.value)
      case 'confirmpassword':
        return setConfirmpassword(e.target.value)
      case 'role':
        return setRole(e.target.value)
      default:
    }
  }
  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      target.preventDefault()
      props.getusers(searchvalue, activePage)
    }
  }
  const onChangeRole = (e) => {
    setRole(e.target.value)
  }

  const onChangeProject = (e) => {

    // alert(e.target.value)
    setprojecttype(e.target.value)

    if (e.target.value == "Direct Projects") {

      var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN')
      axios.get(baseUrl + 'risks/projects/' + localStorage.getItem('companyid'),{headers:{"x-access-token":localStorage.getItem('token')}}).then(function (response) {
        // setLoading(true)
        // alert("11")
        if(response.data.data.length>0){
          setArray(response.data.data.map(items => {
            return {
              label: items.project_name,
              name: "Direct",
              id: items._id
            }
          }))
        }
      

      })
        .catch(function (error) {
          console.log(error);
        })
    }

    else if (e.target.value == "Zoho Projects") {

      var AuthStr = 'Basic ' + sessionStorage.getItem('TOKEN')
      axios.get(baseUrl + 'index/zohoprojects/' + localStorage.getItem('uid'), { headers: { authorization: AuthStr } })
        .then(response => {
          // setLoading(true)
          // alert("22")
          setArray(response.data.data.projects.map(items => {
         
            return {
              label: items.name,
              pnumber: items.id_string,
              name: "Zoho"
            }
          }))
        }).catch(function (error) {
          console.log(error);
        })

    }
  }

  const emailvalidation = (email) => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(email)
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    setIsvalidate(true)
  
    if (firstname && email && phoneno && password) {
      let data = {
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "phoneno": phoneno,
        "password": password,
        "role": role,
        "parentrole": localStorage.getItem('uid'),
        'companyid': localStorage.getItem('companyid'),
        'project_name': selectproject,
        'adminId':localStorage.getItem('uid'),
      }

      // console.log(isvalidate,"uuuuuuuuuuuuuuu");
      if (emailvalidation(email)) {
        setEmailvalidate(false)
        if (password.length > 7) {
          setPwdlength(false)
          if (password) {
            setHideBtnLoad(false)
            edituid ? props.updateuser(data, edituid) : props.adduser(data)
          }
        } else {
          setPwdlength(true)
        }
      } else {
        setEmailvalidate(true)
      }
    }
  }

  const removeToast = () => {
    setTimeout(() => {
      setShowToast(false)
      setToastMsg('')
    }, 5000)
  }

  const onDelete = () => {

    // const role=localStorage.getItem('role')
    // console.log(role,"uuuuuuuu");
    // var body={role}
    // console.log(body,"[[[[[[[[[");
    axios.delete(baseUrl + 'user/testdelete/' + deleteid )
      .then(response => {
        console.log(response,"qqqqqqqqqqq");
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setShow(false)
        setShowToast(true)
        setDeletePopup(false)
        setToastMsg(response.data.message)
        setMsgClass('success')
        setHideBtnLoad(true)
        removeToast()
        props.getusers(searchvalue, activePage)
      })
      .catch(function (error) {
        console.log(error.message)
      });
  }

  const handlePageChange = (pagenum) => {
    console.log("test pagenumm",pagenum);
    setActivePage(pagenum)
    props.getusers(searchvalue, pagenum)
  }

  useMemo(() => {
    if (props.add_user_suc || props.edit_user_suc) {
      let message = (props.add_user_suc != '' ? props.add_user_suc.message : props.edit_user_suc.message);
      setShow(false)
      setShowToast(true)
      setToastMsg(message)
      setMsgClass('success')
      setHideBtnLoad(true)
      removeToast()
      props.getusers(searchvalue, activePage)
    }
  }, [props.add_user_suc, props.edit_user_suc])

  useMemo(() => {
    if (props.add_user_fail) {
      setShow(false)
      setShowToast(true)
      setToastMsg(props.add_user_fail)
      setMsgClass('error')
      setHideBtnLoad(true)
      removeToast()
    }
  }, [props.add_user_fail])

// console.log("tetstst56767111111111111", props.getallusers_suc   );
  return (
    <div className="black">
        {logouloder && <LogoutModal />}
      <Header />
      <div className="pageContain">
        <Sidebar />
        <div className="contentWrapper">
          <div className="content all_risk_hub riskinput">
            <Toast animation={false} show={showtoast} className={`toast_msgs ${msgclass}`}>
              <Toast.Body>
                <strong className="mr-auto">{toastmsg}</strong>
              </Toast.Body>
            </Toast>
            <div className="row pageTitleHeading lagafg" style={{ padding: 10 }}>
              <span className="pageTitleHeadingIcon">
                <FaUsers />
              </span>
              All Users


            </div>
            <span style={{ marginLeft: 20, marginBottom: 10, display: 'flex', justifyContent: "end" }}>
              <Button variant="primary userclear adduserbutton" style={{ backgroundColor: "#0F2869", borderColor: "#0F2869" }} onClick={() => handleShow('add', '')}>Add User</Button>

            </span>
            <Navbar bg="light" variant="light" className="device_navbar" >
              <Form inline className="search_form" style={{ padding: 10 }}>
                <FormControl type="text" placeholder="Search" name="searchvalue" id="usersearch" onChange={handleSearch} value={searchvalue} className="mr-sm-2" onKeyPress={handleKeyPress} />
                <Button variant="outline-primary" onClick={() => searchDevice()} >Search</Button>
                <Button variant="outline-primary userclear" onClick={() => clearDevice()} >Clear</Button>
              </Form>
            </Navbar>

            {/* Add / Update users */}
            <div className="pageTitleButtons text-right">
              <Modal className="delete_popup" show={deletePopup} onHide={handleClose}>
                <Modal.Body>
                  <FaExclamationCircle />
                  <h3>Are you sure you want to delete this user?</h3>
                  <p>You won't be able to revert this!</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                  <Button variant="primary" onClick={onDelete}>Yes, delete it!</Button>
                </Modal.Footer>
              </Modal>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  {formType === 'add' ? <Modal.Title>Add User</Modal.Title> : <Modal.Title>Edit User</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={handleSubmit}>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>First Name *</Form.Label>
                          <div className="inputWithIcon">
                            <FaUserAlt />
                            <Form.Control type="text" name="firstname" onChange={handleFieldValue} placeholder="First Name" value={firstname} />
                          </div>
                          {isvalidate && firstname == '' ? <Form.Label className="validationError">Please enter first name</Form.Label> : null}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Last Name</Form.Label>
                          <div className="inputWithIcon">
                            <FaUserAlt />
                            <Form.Control type="text" name="lastname" onChange={handleFieldValue} placeholder="Last Name" value={lastname} />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col >
                        <Form.Group>
                          <Form.Label>Email *</Form.Label>
                          <div className="inputWithIcon">
                            <FaEnvelopeOpenText />
                            {formType === 'add' ? <Form.Control type="text" name="email" onChange={handleFieldValue} placeholder="Email Address" defaultValue={email} /> : <Form.Control disabled type="text" name="email" onChange={handleFieldValue} placeholder="Email Address" defaultValue={email} />}
                          </div>
                          {isvalidate && email == '' ? <Form.Label className="validationError">Please enter email</Form.Label> : isvalidate && emailvalidate ? <Form.Label className="validationError">Please enter valid email address</Form.Label> : null}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Phone Number *</Form.Label>
                          <div className="inputWithIcon">
                            <FaPhoneAlt />
                            <Form.Control type="number" name="phoneno" onChange={handleFieldValue} placeholder="Phone Number" value={phoneno} />
                          </div>
                          {isvalidate && phoneno == '' ? <Form.Label className="validationError">Please enter phone number</Form.Label> : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Role</Form.Label>
                          <div className="inputWithIcon">
                            <FaUserAlt />
                            <Form.Control as="select" name="role" onChange={onChangeRole} defaultValue={role}>
                              <option value="admin">Admin</option>
                              <option value="user">User</option>
                            </Form.Control>
                            {/* {formType === 'add' ?  <Form.Control as="select" name="role" onChange={onChangeRole} defaultValue={role}>
                              <option value="admin">Admin</option>
                              <option value="user">User</option>
                            </Form.Control> : <Form.Control as="select" disabled={role == 'admin'} name="role" onChange={onChangeRole} defaultValue={role}>
                              <option value="admin">Admin</option>
                              <option value="user">User</option>
                            </Form.Control>  } */}

                          </div>
                        </Form.Group>
                      </Col>
                     
                      <Col>
                        <Form.Group>
                          <Form.Label>New Password *</Form.Label>
                          <div className="inputWithIcon">
                            <FaLock />
                            <Form.Control type={showpwd ? 'text' : 'password'} placeholder="Password" name="password" onChange={handleFieldValue} value={password} />
                            <i className = "eyeicon" onClick={toggleShow} >{showpwd ? <FaEyeSlash /> : <FaEye />}</i>
                          </div>

                          {isvalidate && password == '' ? <Form.Label className="validationError">Please enter password</Form.Label> : isvalidate && pwdlength ? <Form.Label className="validationError">Please enter minimum 8 characters</Form.Label> : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                     <Col>
                      <Form.Group>
                          <Form.Label>Project Type</Form.Label>
                          <div className="inputWithIcon">
                            <FaUserAlt />
                            <Form.Control as="select" name="Projecttype" onChange={onChangeProject} defaultValue={role}>
                              <option value="Direct Projects">Direct Projects</option>
                              <option value="Zoho Projects">Zoho Projects</option>
                            </Form.Control>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col>
             
                         <Form.Group>
                          <Form.Label>Project Name</Form.Label>
                          <Multiselect
                            options={userarray}
                            displayValue="label"
                            onSelect={mutiselectproject}
                            showCheckbox={true}
                            selectedValues={selectproject}
                          />
                        </Form.Group>
                    
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                      </Col>
                    </Row>

                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  {hidebtnload ? <Button className="updatemyprofilebutton" variant="primary" onClick={handleSubmit}>Submit</Button> :
                    <Button className="updatemyprofilebutton" variant="primary" type="submit" onClick={handleSubmit} disabled >
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </Button>}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="table_scroll">
            <Table striped bordered hover responsive className="defaultTable">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Phone Number</th>
                  <th className="center_align">Actions</th>
                </tr>
              </thead>
              <tbody>
               
                {props.getallusers_suc ? props.getallusers_suc.Records.length > 0 ? props.getallusers_suc.Records.map((deatils, i) => (
                 console.log(deatils,";;;;;;;"),
                 <tr key={i}>
                    <td>{deatils.firstname}</td>
                    <td>{deatils.lastname}</td>
                    <td>{deatils.email}</td>
                    <td>{deatils.role}</td>
                    <td>{deatils.phoneno}</td>
                    <td className="center_align">
                      <div className="actionBtns">
                        <Button title="Edit" variant="link" onClick={() => handleShow('edit', deatils)} className="text-primary"><FaRegEdit /></Button>
                        <Button title="Delete" variant="link" className="text-danger" onClick={() => handleDeleteShow(deatils._id)} ><FaTrashAlt /></Button>
                      </div>
                    </td>
                  </tr>
                )) : <tr><td colSpan="21" className="empty_records">No records found</td></tr> : <tr><td colSpan="21" className="empty_records">No records found</td></tr>}
              </tbody>
            </Table>
            {/* {props.getallusers_suc && props.getallusers_suc.Count > 0 ? <Pagination activePage={activePage} itemsCountPerPage={1} totalItemsCount={props.getallusers_suc ? props.getallusers_suc.Count > 0 ? props.getallusers_suc.Count : 0 : 0} pageRangeDisplayed={5} onChange={handlePageChange} /> : null} */}
            {props.getallusers_suc && props.getallusers_suc.Count > 0 ? <Pagination activePage={activePage} itemsCountPerPage={10} totalItemsCount={props.getallusers_suc ? props.getallusers_suc.Count > 0 ? props.getallusers_suc.Count : alert("ok") : alert("not")} pageRangeDisplayed={5} onChange={handlePageChange} /> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ users: { add_user_suc, add_user_fail, getallusers_suc, getallusers_fail, edit_user_suc, edit_user_fail } }) => ({
  add_user_suc, add_user_fail, getallusers_suc, getallusers_fail, edit_user_suc, edit_user_fail
})

const mapDispatchToProps = {
  getusers: usersActions.getUsers,
  adduser: usersActions.addusers,
  updateuser: usersActions.updateuser,
  getProjects: riskActions.getProjectsList
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);