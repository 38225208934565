import {
    userConstants
} from "../actions/constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {
    loading: true,
    error : false,
    message: undefined,
    user,
    login_err : '',
    user_req : '',
    reg_user_req : '',
    reg_user_suc : '',
    reg_user_fail : '',
    reset_user_req : '',
    reset_user_suc : '',
    reset_user_fail : '',
} : {};

function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                user_req: action.user,
                login_err:''
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                login_err : action.error
            };
        case userConstants.REGISTRATION_REQUEST:
            return {
                ...state,
                loading: true,
                reg_user_req: action.registeruser,
                reg_user_suc: '',
                reg_user_fail : ''
            };
        case userConstants.REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: false,
                reg_user_suc: action.registeruser
            };
        case userConstants.REGISTRATION_FAILURE:
            return {
                ...state,
                loading: false,
                reg_user_fail: action.registererror
            };
        case userConstants.RESET_REQUEST:
            return {
                ...state,
                loading: true,
                reset_user_req: action.resetuser,
                reset_user_suc : '',
                reset_user_fail : ''
            };
        case userConstants.RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                reset_user_suc: action.resetuser
            };
        case userConstants.RESET_FAILURE:
            return {
                ...state,
                loading: false,
                reset_user_fail: action.reseterror
            };
        case userConstants.LOGOUT:
            return {};
        default:
            return state;
    }
}

export default authentication;